import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../store/actions';

const InfoMessage = () => {
    const dispatch = useDispatch();
    const message = useSelector((state: any) => state.message);

    const handleSnackBarClose = () => {
        dispatch(setMessage());
    };

    return (
        <Snackbar
            open={message.isOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackBarClose}
            autoHideDuration={10000}
        >
            {message.type && message.content && <Alert
                variant="filled"
                severity={message.type}
                onClose={handleSnackBarClose}
            >
                {message.content}
            </Alert>}
        </Snackbar>
    );
};
export default InfoMessage;