import React from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShieldIcon from '@mui/icons-material/Shield';
import styled from '@emotion/styled';
import { WCard } from '../../styles/common';
import { Link } from 'react-router-dom';
import { Colors } from '../../styles/colors';
import { useSelector } from 'react-redux';

const PageNavigator = ({ active }: { active?: string }) => {
    const user = useSelector((state: any) => state.user);

    const checkIsActive = (state: string) => state === active;


    // TODO: change to generate it dynamically
    // TODO: add Moje komentarze

    return (
        <PageNavigatorWrapper>
            {/*{user && user.userIsVet && <PageNavigatorLink*/}
            {/*    className={checkIsActive('ask-vet') ? 'active' : ''}*/}
            {/*    to={'/pytania-do-lekarza'}>*/}
            {/*    <QuestionAnswerIcon /> Pytania do lekarza*/}
            {/*</PageNavigatorLink>}*/}
            {/*<PageNavigatorLink*/}
            {/*    className={checkIsActive('my-pets') ? 'active' : ''}*/}
            {/*    to={'/moje-zwierzeta'}>*/}
            {/*    <MenuBookIcon /> Moje zwierzęta*/}
            {/*</PageNavigatorLink>*/}
            <PageNavigatorLink
                className={checkIsActive('profile-settings') ? 'active' : ''}
                to={'/profil-i-ustawienia'}
            >
                <AccountCircleIcon /> Profil i ustawienia
            </PageNavigatorLink>
            {/*{user && user.userIsVet && <PageNavigatorLink*/}
            {/*    className={checkIsActive('profile-verification') ? 'active' : ''}*/}
            {/*    to={'/profil-weryfikacja'}*/}
            {/*>*/}
            {/*    <ShieldIcon /> Weryfikacja profilu*/}
            {/*</PageNavigatorLink>}*/}
            {user && user.userIsOwner && <PageNavigatorLink
                className={checkIsActive('my-vet-clinics') ? 'active' : ''}
                to={'/moje-placowki'}>
                <OtherHousesIcon /> Moje placówki
            </PageNavigatorLink>}
        </PageNavigatorWrapper>
    );
};
export default PageNavigator;

// styles
export const PageNavigatorWrapper = styled(WCard)({
    paddingTop: 10,
    paddingBottom: 10,
});

export const PageNavigatorLink = styled(Link)({
    color: Colors.lightBlack,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: 15,
    svg: {
        marginRight: 10,
    },
    ':hover': {
        backgroundColor: Colors.lightGrey2,
    },
    '&.active': {
        backgroundColor: Colors.lightGrey2,
    },
});
