import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { H1, WBlueButton, WButton, WCard, WLink } from '../../styles/common';
import { CardContent, Grid, Breadcrumbs, Table, TableHead, TableCell, TableBody, TableRow, Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import http from '../../Api/http';
import { setMessage } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/helpers/Loading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { Colors } from '../../styles/colors';

const MyVetClinicsList = () => {
    const [vetClinics, setVetClinics] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(((state: any) => state.user));

    useEffect(() => {
        if (user && user.userIsOwner) {
            loadMyVetClinics();
        } else {
            // TODO return <Navigate to="" />;
        }
    }, []);

    const loadMyVetClinics = () => {
        setIsLoading(true);
        http().get('/user-vets')
            .then(({ data }) => {
                setVetClinics(data);
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Nie można załadować danych, spróbuj jeszcze raz za chwilę lub zgłoś problem administratorowi.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const goToAddClinicPage = () => {
        navigate('/moje-placowki/dodaj');
    };

    const goToEditClinicPage = (vetSlug: string) => {
        navigate(`/moje-placowki/edytuj/${vetSlug}`);
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-placowki`}>Moje placówki</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="my-vet-clinics" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={8} sm={7} xs={12}>
                                        <H1>Moje placówki</H1>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        sm={5}
                                        xs={12}
                                        sx={{
                                            textAlign: {
                                                md: 'right',
                                                sm: 'right',
                                            },
                                        }}
                                    >
                                        <WBlueButton
                                            onClick={goToAddClinicPage}
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                        >Dodaj placówkę</WBlueButton>
                                    </Grid>
                                </Grid>

                                {isLoading && <Loading />}

                                {!isLoading && vetClinics.length > 0 && <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nazwa</TableCell>
                                            <TableCell>Miejscowość</TableCell>
                                            <TableCell align="center">Aktywna</TableCell>
                                            <TableCell> </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vetClinics.map((vet: any, ind: number) => {
                                            return (
                                                <TableRow key={ind}>
                                                    <TableCell>{vet.name}</TableCell>
                                                    <TableCell>{vet['City.name']}</TableCell>
                                                    <TableCell align="center">
                                                        {!!vet.isActive && <CheckCircleIcon sx={{ color: Colors.successGreen }} />}
                                                        {!vet.isActive && <CancelIcon sx={{ color: Colors.errorRed}} />}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'right' }}>
                                                        <WButton
                                                            onClick={() => goToEditClinicPage(vet.slug)}
                                                            size="small"
                                                            variant="outlined"
                                                            startIcon={<EditIcon />}
                                                        >Edytuj</WButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}

                                {!isLoading && vetClinics.length === 0 && <Box>
                                    <p>Nie dodano jeszcze żadnej placówki.</p>
                                </Box>}
                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default MyVetClinicsList;
