import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { H1, WCard } from '../../styles/common';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import config from '../../config/config';

const Paragraph = styled(Box)({
    textAlign: 'center',
    fontWeight: 'bold',
});

const ContentBox = styled(Box)({
    paddingTop: 20,
});

const TermsConditionsPage = () => {
    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{'Regulamin - weteo.pl'}</title>
                    <meta name="description" content="Treść regulaminu serwisu weteo.pl" />
                    <link rel="canonical" href={`${config.baseUrl}/regulamin`} />
                </Helmet>
                <WCard sx={{ mt: '20px', mb: '20px' }}>
                    <CardContent>
                        <Grid container>
                            <Grid item md={12}>
                                <H1>Regulamin</H1>
                                <Paragraph>§1</Paragraph>
                                <Paragraph>Pojęcia</Paragraph>
                                <ContentBox>
                                    Pojęcia używane w niniejszym Regulaminie mają znaczenia jak niżej:
                                    <br/>
                                    <ol>
                                        <li><strong>Sprzedawca</strong> – firma ELITEIT z siedzibą w Poznaniu, dostarczająca Usługi za pośrednictwem
                                            Serwisu
                                        </li>
                                        <li><strong>Użytkownik</strong> – usługobiorca, przedsiębiorca lub konsument, kupujący,
                                            każdy, kto korzysta z Usług udostępnianych za pomocą Serwisu przez Sprzedawcę
                                        </li>
                                        <li><strong>Serwis</strong> – system aplikacji internetowych stanowiących zbiór
                                            dokumentów statycznych i dynamicznych, zawierających pliki, w tym graficzne, skrypty
                                            oraz inne elementy zbioru połączone wzajemnymi relacjami udostępniony pod domeną
                                            weteo.pl
                                        </li>
                                        <li><strong>Usługi</strong> – wykonanie świadczenia bez jednoczesnej obecności Stron
                                            poprzez przekaz danych na indywidualne żądanie Użytkownika, przesyłanych i
                                            otrzymywanych za pomocą urządzeń do elektronicznego przetwarzania, i przechowywania
                                            danych, które są w całości wysyłane i odbierane lub transmitowane za pomocą sieci
                                            Internet
                                        </li>
                                        <li><strong>Mail</strong> – adres poczty elektronicznej</li>
                                        <li><strong>Strony</strong> – Sprzedawca i Użytkownik</li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§2</Paragraph>
                                <Paragraph>Postanowienia Ogólne</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Właścicielem Serwisu jest Sprzedawca.</li>
                                        <li>Regulamin określa rodzaje oraz warunki świadczenia usług za pomocą Serwisu przez
                                            Sprzedawcę oraz zasady korzystania z Serwisu, jak również warunki zawierania i
                                            rozwiązywania umów, a także tryb postępowania reklamacyjnego.
                                        </li>
                                        <li>Regulamin jest dostępny w Serwisie w formie umożliwiającej jego zapisanie i
                                            wydrukowanie.
                                        </li>
                                        <li>Jeżeli zajdzie konieczność zmiany Regulaminu, Sprzedawca udostępnieni w Serwisie
                                            jego nową wersję, o czym zawiadomi Użytkownika mailem. Użytkownik, który nie akceptuje
                                            zmiany Regulaminu jest
                                            zobowiązany poinformować Sprzedawcę o braku akceptacji w terminie 14 dni od daty z
                                            jaką wprowadzono informację do środka komunikacji elektronicznej w taki sposób, żeby
                                            osoba ta mogła zapoznać się z jej treścią. Do świadczenia usług ma zastosowanie
                                            Regulamin w brzmieniu obowiązującym na dzień zawarcia umowy.
                                        </li>
                                        <li>Zakazane jest dostarczanie przez Użytkownika treści innych niż określone
                                            Regulaminem, reklamowych lub o charakterze bezprawnym, a także podejmowanie działań
                                            mogących wywołać wadliwe funkcjonowanie Serwisu. W razie otrzymania przez Sprzedawcę
                                            zawiadomienia lub wiarygodnej informacji o bezprawnym charakterze danych
                                            dostarczanych przez Użytkownika, Sprzedawca może uniemożliwić dostęp do tych danych.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§3</Paragraph>
                                <Paragraph>Rodzaj i zakres działalności</Paragraph>
                                <ContentBox>
                                    Sprzedawca prowadzi działalność polegającą na świadczeniu usług za pośrednictwem Serwisu w
                                    postaci treści przekazywanych i odbieranych z wykorzystaniem sieci Internet polegające na
                                    udostępnieniu platformy do przeglądania wizytówek placówek dla zwierząt, możliwością
                                    dodania opinii o placówce, rezerwacji wizyty w placówce, zarządzania wizytówką placówki,
                                    możliwością zadania pytania i uzyskania odpowiedzi od lekarzy weterynarii, prowadzenia elektronicznej
                                    książeczki zdrowia zwierzęcia.
                                </ContentBox>

                                <Paragraph>§4</Paragraph>
                                <Paragraph>Zawarcie Umowy</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Korzystanie z Serwisu jest darmowe - chyba, że oznaczono inaczej</li>
                                        <li>Informacje zamieszczone w Serwisie nie stanowią oferty zawarcia umowy dotyczącej
                                            oferowanych w nim Usług, tylko zaproszenie do składania ofert.
                                        </li>
                                        <li>Przez prawidłowe złożenie zamówienia Użytkownik składa Sprzedawcy ofertę zawarcia
                                            umowy.
                                        </li>
                                        <li>Chwilą zawarcia Umowy jest chwila opłacenia przez Użytkownika wybranego pakietu
                                            Usług.
                                        </li>
                                        <li>Umowa dochodzi do skutku po cenach i na warunkach, które obowiązywały w chwili
                                            złożenia przez Użytkownika oferty.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§5</Paragraph>
                                <Paragraph>Realizacja Zamówienia</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Realizacja zamówienia następuje niezwłocznie po złożeniu zamówienia. Szczegółowe
                                            zasady realizacji zamówień na określone Usługi zawarte są w opisach danej Usługi.
                                        </li>
                                        <li>Jeżeli nie można będzie zrealizować zamówienia w terminie określonym w niniejszym
                                            Regulaminie z uwagi na brak dostępności Usługi, Sprzedawca powiadomi o tym
                                            niezwłocznie Użytkownika za pomocą poczty elektronicznej. Jeżeli Użytkownik nie
                                            zaakceptuje zmienionego terminu realizacji zamówienia wszystko co do tej pory Strony
                                            świadczyły ulegnie zwrotowi w stanie niezmienionym i żadna ze Stron nie będzie
                                            podnosić wobec siebie jakichkolwiek roszczeń z tytułu braku realizacji zamówienia.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§6</Paragraph>
                                <Paragraph>Dostawa Usługi</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Zamówione Usługi zostaną dostarczone za pomocą Serwisu.</li>
                                        <li>Użytkownik uzyskuje dostęp do zamówionej Usługi poprzez poprawne zalogowanie się do
                                            Serwisu i przejście do strony z wybraną Usługą.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§7</Paragraph>
                                <Paragraph>Odstąpienie</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Użytkownik może odstąpić od zawartej umowy bez podania przyczyn, składając stosowne
                                            oświadczenie na piśmie na adres e-mail Sprzedawcy w terminie 14 dni od
                                            dnia zawarcia umowy świadczenia Usługi. Do zachowania tego terminu wystarczy
                                            wysłanie oświadczenia przed jego upływem.
                                        </li>
                                        <li>Sprzedawca niezwłocznie, nie później niż w ciągu 14 dni od dnia otrzymania
                                            oświadczenia Użytkownika o odstąpieniu od umowy, o którym mowa w ust. 1. zwraca
                                            wpłacone pieniądze w sposób wskazany przez Użytkownika.
                                        </li>
                                        <li>Odstąpienie od umowy świadczenia usługi możliwe jest tylko wtedy gdy Użytkownik nie
                                            rozpoczął korzystania z Usługi o czym świadczyć będą odpowiednie zapisy z bazy
                                            danych Serwisu.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§8</Paragraph>
                                <Paragraph>Wymagania techniczne</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>
                                            Celem korzystania z Serwisu konieczne jest:
                                            <br/>
                                            <ul>
                                                <li>połączenie z siecią Internet,</li>
                                                <li>przeglądarka internetowa umożliwiająca poprawne przetwarzanie kodu HTML5,
                                                    CSS3.
                                                    <br/>
                                                    Google Chrome w najnowszej wersji lub Firefox w najnowszej wersji.
                                                </li>
                                                <li>włączona obsługa Cookies i Java Script</li>
                                            </ul>
                                        </li>
                                        <li>W razie korzystania przez Użytkownika ze sprzętu lub oprogramowania, które nie
                                            spełnia wymogów technicznych określonych w ust.1, Sprzedawca nie gwarantuje
                                            prawidłowości funkcjonowania Serwisu.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§9</Paragraph>
                                <Paragraph>Płatności</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Użytkownik jest zobowiązany do zapłaty za zamówioną Usługę.</li>
                                        <li>
                                            Cena i inne istotne warunki związane z Usługą są każdorazowo podane w opisie
                                            publikowanym na stronach Serwisu. Ceny zawierają wszystkie podatki.
                                        </li>
                                        <li>Zapłata za Usługę następuje przed dostarczeniem Usługi.</li>
                                        <li>Zapłata jest możliwa w formie płatności online za pośrednictwem systemu DotPay
                                            (dotpay.pl).
                                        </li>
                                        <li>Podane w Serwisie kwoty są cenami brutto, co oznacza, że zawierają podatek od
                                            towarów i usług VAT.
                                        </li>
                                        <li>Użytkownik wyraża zgodę i upoważnia Sprzedawcę do wystawienia faktury VAT bez
                                            podpisu odbiorcy oraz do wystawienia e-faktury.
                                        </li>
                                        <li>Jeżeli Użytkownik zgłosi w formularzu zamówienia Usługi żądanie wystawienia faktury za
                                            świadczone Usługi, to jest obowiązany podać żądane dane, niezbędne do wystawienia
                                            faktury.
                                        </li>
                                        <li>Faktura VAT jest wystawiana tylko na żądanie Użytkownika.</li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§10</Paragraph>
                                <Paragraph>Tryb postępowania reklamacyjnego</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>
                                            Użytkownik może składać reklamacje w sprawach dotyczących błędów w poprawnym
                                            działaniu Usług poprzez wysłanie zgłoszenia w postaci elektronicznej na adres e-mail
                                            Sprzedawcy.
                                        </li>
                                        <li>
                                            Prawidłowo złożona reklamacja powinna zawierać co najmniej następujące dane:
                                            <br/>
                                            oznaczenie Użytkownika (w tym jego imię i nazwisko - jeśli były podane, adres e-mail
                                            oraz dane kontaktowe osoby upoważnionej do załatwiania spraw związanych ze złożoną
                                            reklamacją);
                                            <br/>
                                            opis problemu będącego podstawą złożenia reklamacji (w tym dokładny opis błędu,
                                            kroki wykonane aż do uzyskania błędu, informacje o posiadanym systemie, wersji
                                            przeglądarki oraz zrzuty ekranu z opisywanym błędem)
                                        </li>
                                        <li>Sprzedawca rozpatrzy reklamację w terminie 14 dni od ich otrzymania i zawiadomi
                                            składającego o wyniku rozpatrzenia reklamacji.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§11</Paragraph>
                                <Paragraph>Polityka Prywatności</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Administratorem danych osobowych w stosunku do informacji podawanych przez
                                            Użytkowników podczas korzystania z Serwisu jest Sprzedawca
                                        </li>
                                        <li>Dane osobowe Sprzedawca będzie przetwarzać zgodnie z zasadami określonymi w Ustawie
                                            o ochronie danych osobowych, Ustawie o świadczeniu usług drogą elektroniczną w
                                            celach związanych z Dostarczaniem Usług.
                                        </li>
                                        <li>Dane osobowe Sprzedawca będzie przetwarzać zgodnie z zasadami określonymi w Ustawie
                                            o ochronie danych osobowych, Ustawie o świadczeniu usług drogą elektroniczną również
                                            w celach związanych z marketingiem bezpośrednim własnych produktów i usług, jak
                                            również w celu badania rynku oraz w celach związanych z przesyłaniem informacji
                                            handlowych drogą elektroniczną od Sprzedawcy.
                                        </li>
                                        <li>Użytkownik wraża zgodę niniejszym i poprzez złożenie oświadczeń o wyrażeniu zgody na
                                            przetwarzanie danych w zakresie wskazanym w ust. 2 oraz w ust. 3, co następuje
                                            poprzez dokonanie rejestracji w Serwisie.
                                        </li>
                                        <li>Użytkownik może korzystać z Serwisu anonimowo, chyba, że podanie określonych danych
                                            osobowych jest konieczne do Dostarczenia Usługi.
                                        </li>
                                        <li>Zakres przetwarzanych danych obejmuje w szczególności następujące dane osobowe
                                            Użytkownika: imię i nazwisko, nazwę firmy w przypadku osób fizycznych prowadzących
                                            działalność gospodarczą oraz do celów rozliczeń, rachunkowych - Numer Identyfikacji
                                            Podatkowej, adres, w tym adres e-mail.
                                        </li>
                                        <li>Sprzedawca jest uprawniony do przetwarzania również innych niż te wskazane powyżej w
                                            ust. 3 danych osobowych Użytkowników, jeżeli są niezbędne do realizacji zamówienia.
                                            W takim przypadku dane te będą każdorazowo wyraźnie oznaczane jako dane niezbędne do
                                            wykonania zamówienia.
                                        </li>
                                        <li>Jeżeli doszłoby do korzystania z Serwisu w sposób sprzeczny z prawem, Sprzedawca ma
                                            prawo do przetwarzania danych osobowych Użytkownika w zakresie niezbędnym do
                                            ustalenia jego odpowiedzialności. Sprzedawca powiadomi Użytkownika o niedozwolonych
                                            działaniach z żądaniem ich niezwłocznego zaprzestania oraz o przetwarzaniu danych
                                            osobowych w powyżej określonym celu.
                                        </li>
                                        <li>
                                            Dane osobowe zgromadzone przez Sprzedawcę mogą być także udostępnione:
                                            <br/>
                                            <ul>
                                                <li>odpowiednim organom państwowym na ich żądanie na podstawie odpowiednich
                                                    przepisów prawa,
                                                </li>
                                                <li>innym osobom i podmiotom – w przypadkach przewidzianych przez przepisy
                                                    prawa.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Sprzedawca zapewnia odpowiednie środki techniczne i organizacyjne zapewniające
                                            bezpieczeństwo danych osobowych udostępnionych przez Użytkowników, w szczególności
                                            uniemożliwiające dostęp do nich osobom trzecim lub ich przetwarzanie z naruszeniem
                                            przepisów prawa, zapobiegające utracie danych, ich uszkodzeniu lub zniszczeniu.
                                        </li>
                                        <li>
                                            Użytkownikowi przysługuje prawo:
                                            <br/>
                                            <ul>
                                                <li>dostępu do swoich danych osobowych, prawo dokonywania uzupełnienia, korekt
                                                    oraz aktualizacji treści danych w drodze komunikacji z Sprzedawcę;
                                                </li>
                                                <li>żądania czasowego lub stałego wstrzymania przetwarzania danych osobowych lub
                                                    ich usunięcia, o ile okazałyby się one niekompletne, nieaktualne,
                                                    nieprawdziwe lub zebrane z naruszeniem przepisów prawa, w tym celu należy
                                                    wysłać pismo na adres e-mail Sprzedawcy;
                                                </li>
                                                <li>sprzeciwu wobec przetwarzania ich danych osobowych – w przypadkach
                                                    przewidzianych przepisami prawa – oraz prawo żądania ich usunięcia, gdy
                                                    staną się one zbędne do realizacji celu, dla którego zostały zebrane, w tym
                                                    celu należy wysłać pismo na adres e-mail Sprzedawcy.
                                                </li>
                                                <li>Po zakończeniu korzystania z Serwisu przez Użytkownika, Sprzedawca nie
                                                    będzie przetwarzać danych osobowych wymienionych w ust. 6, za wyjątkiem tych
                                                    danych, które są: dopuszczone do przetwarzania na podstawie przepisów prawa
                                                    lub umowy, niezbędne do wyjaśnienia okoliczności niedozwolonego korzystania
                                                    z usług. Sprzedawca usunie w takim przypadku wszelkie oznaczenia
                                                    identyfikujące Użytkownika oraz zakończenie sieci telekomunikacyjnej i
                                                    system teleinformatyczny, z którego korzystał Użytkownik, chyba że
                                                    Użytkownik wyrazi zgodę na nieusuwanie tych oznaczeń.
                                                </li>
                                                <li>Dane, o których mowa w ust. 8 przetwarzane będą tylko w zakresie niezbędnym
                                                    dla zrealizowania Usługi i po jej zakończeniu zostaną niezwłocznie
                                                    usunięte przez Sprzedawcę.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§12</Paragraph>
                                <Paragraph>Własność intelektualna</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Sprzedawca oświadcza, że w związku ze świadczeniem Usług na rzecz Użytkownika,
                                            udostępnia Użytkownikowi treści chronione prawem własności intelektualnej, w
                                            szczególności utwory chronione prawem autorskim.
                                        </li>
                                        <li>Użytkownik zobowiązany jest przestrzegać przepisów prawa własności intelektualnej. W
                                            szczególności wszelkie kopiowanie, wprowadzanie zmian, a także publiczne odtwarzanie
                                            udostępnianych treści bez pisemnej zgody Użytkownika, jest zakazane, o ile nie
                                            wynika to z odrębnych umów lub bezwzględnie obowiązujących przepisów prawa.
                                        </li>
                                        <li>Sprzedawca w zakresie korzystania z Usług udziela licencji niewyłącznej,
                                            nieograniczonej w czasie do korzystania z Usług dla własnych potrzeb Użytkownika.
                                        </li>
                                        <li>
                                            Wszelkie dane teleadresowe (w tym nazwa) placówek dla zwierząt pochodzą z
                                            publicznych, ogólnodostępnych źródeł. Właścicielowi placówki przysługuje prawo do
                                            zmiany danych placówki lub ich usunięcia. W tym celu należy skontaktować się ze Sprzedawcą
                                            poprzez zakładkę Kontakt, chyba, że w Serwisie udostępniono inną opcję kontaktu.
                                        </li>
                                    </ol>
                                </ContentBox>


                                <Paragraph>§13</Paragraph>
                                <Paragraph>Postanowienia końcowe</Paragraph>
                                <ContentBox>
                                    <ol>
                                        <li>Sprzedawca ma prawo zablokować dostęp do Serwisu lub jego części z ważnych przyczyn,
                                            w tym w szczególności w razie stwierdzenia nieprawidłowości w korzystaniu z Serwisu,
                                            lub wystąpienia okoliczności, które mogłyby narazić na szkodę Użytkownika lub
                                            Sprzedawcę.
                                        </li>
                                        <li>Użytkownik nie udziela gwarancji, że korzystanie z Serwisu będzie przebiegało
                                            niewadliwie.
                                        </li>
                                        <li>W odniesieniu do informacji prezentowanych w ramach Serwisu, które zostały w nim
                                            zamieszczone lub dostarczone przez podmioty trzecie, Sprzedawca nie ponosi
                                            odpowiedzialności za aktualność, dokładność, lub kompletność tych informacji, jak
                                            również za ich przydatność dla jakichkolwiek działań Użytkownika.
                                        </li>
                                        <li>Sprzedawca nie ponosi ponadto odpowiedzialności za korzystanie przez Użytkownika z
                                            Serwisu w sposób sprzeczny z postanowieniami Regulaminu, obowiązującymi przepisami
                                            prawa, lub postanowieniami umów obowiązujących pomiędzy Sprzedawcą a Użytkownikiem.
                                        </li>
                                        <li>Za wszelkie skutki wynikłe z niewłaściwego wypełnienia przez Użytkownika formularzy
                                            dostępnych w Serwisie, w szczególności polegającego na podaniu przez Użytkownika
                                            błędnych lub nieprawdziwych danych, odpowiedzialność ponosi Użytkownik.
                                        </li>
                                        <li>Postanowienia Regulaminu nie naruszają możliwości powoływania się przez Użytkowników
                                            będących konsumentami na bezwzględnie obowiązujące przepisy prawa regulujące ochronę
                                            praw konsumentów.
                                        </li>
                                        <li>W sprawach nieuregulowanych w Regulaminie odpowiednie zastosowanie znajdują przepisy
                                            prawa polskiego, w tym ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny oraz
                                            ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, a także
                                            innych obowiązujących przepisów prawa.
                                        </li>
                                        <li>Spory wynikłe na tle interpretacji lub wykonania Regulaminu będą rozstrzygane przez
                                            sąd właściwy według przepisów prawa.
                                        </li>
                                    </ol>
                                </ContentBox>
                                <p>Wersja regulaminu 1.0.0 z dnia 27.12.2021r.</p>
                            </Grid>
                        </Grid>
                    </CardContent>
                </WCard>
            </Layout>
        </HelmetProvider>
    );
};

export default TermsConditionsPage;
