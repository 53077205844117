import React, { useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import { handleErrors } from '../../../utils/helpers';
import Loading from '../../../components/helpers/Loading';
import { FormGroup, FormItemError, WButton, H5 } from '../../../styles/common';
import { useDispatch } from 'react-redux';
import { provinces, vetTypes } from '../../../config/constants';
import { useNavigate } from 'react-router-dom';

const initialVetClinicData: any = {
    name: '',
    type: 10,
    street: '',
    zip: '',
    city: '',
    province: 1,
    phone: '',
    phone2: '',
};

const AddVetClinicForm = () => {
    const [vetClinicData, setUserData] = useState<any>(initialVetClinicData);
    const [errors, setErrors] = useState<any>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const user = useSelector(((state: any) => state.user));
    // TODO: check user type to redirect if now owner

    const onFieldChange = (field: string, value: any) => {
        const newUserData: any = { ...vetClinicData };
        newUserData[field] = value;
        setUserData(newUserData);
    };

    const onSubmit = (ev: any) => {
        ev.preventDefault();
        setErrors({});
        const dataToSave = {...vetClinicData};
        setIsSaving(true);
        http().post('/user-vets', dataToSave)
            .then(({ data }) => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Gabinet został dodany.',
                    type: 'success',
                }));
                if (data && data.slug) {
                    navigate(`/moje-placowki/edytuj/${data.slug}`);
                }
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <Box
            component="form"
            onSubmit={onSubmit}
        >

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('name', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Nazwa"
                        size="small"
                        value={vetClinicData.name || ''}
                        error={errors.name && errors.name.length > 0}
                    />
                    {errors.name && <FormItemError>{errors.name}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Prowadzona działalność</InputLabel>
                        <Select
                            onChange={(ev) => onFieldChange('type', ev.target.value)}
                            disabled={isSaving}
                            required={true}
                            fullWidth
                            label="Prowadzona działalność"
                            size="small"
                            value={vetClinicData.type || ''}
                            error={errors.type && errors.type.length > 0}
                        >
                            {vetTypes.map((vetType, key: number) => {
                                return (
                                    <MenuItem value={vetType.id} key={key}>
                                        {vetType.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {errors.type && <FormItemError>{errors.type}</FormItemError>}
                </Grid>
            </FormGroup>

            <Box sx={{ mb: '10px' }}>
                <H5>Adres placówki</H5>
            </Box>
            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('street', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Ulica i numer"
                        size="small"
                        value={vetClinicData.street || ''}
                        error={errors.street && errors.street.length > 0}
                    />
                    {errors.street && <FormItemError>{errors.street}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('zip', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Kod pocztowy"
                        size="small"
                        value={vetClinicData.zip || ''}
                        error={errors.zip && errors.zip.length > 0}
                    />
                    {errors.zip && <FormItemError>{errors.zip}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('city', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Miejscowość"
                        size="small"
                        value={vetClinicData.city || ''}
                        error={errors.city && errors.city.length > 0}
                    />
                    {errors.city && <FormItemError>{errors.city}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Województwo</InputLabel>
                        <Select
                            onChange={(ev) => onFieldChange('province', ev.target.value)}
                            disabled={isSaving}
                            required={true}
                            fullWidth
                            label="Województwo"
                            size="small"
                            value={vetClinicData.province || ''}
                            error={errors.province && errors.province.length > 0}
                        >
                            {provinces.map((province, key: number) => {
                                return (
                                    <MenuItem value={province.id} key={key}>
                                        {province.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {errors.province && <FormItemError>{errors.province}</FormItemError>}
                </Grid>
            </FormGroup>


            <Box sx={{ mb: '10px' }}>
                <H5>Dane kontaktowe</H5>
            </Box>
            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('phone', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Numer telefonu 1."
                        size="small"
                        type="tel"
                        value={vetClinicData.phone || ''}
                        error={errors.phone && errors.phone.length > 0}
                    />
                    {errors.phone && <FormItemError>{errors.phone}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('phone2', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Numer telefonu 2."
                        size="small"
                        type="tel"
                        value={vetClinicData.phone2 || ''}
                        error={errors.phone2 && errors.phone2.length > 0}
                    />
                    {errors.phone2 && <FormItemError>{errors.phone2}</FormItemError>}
                </Grid>
            </FormGroup>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    {isSaving && <Loading />}

                    {!isSaving && <WButton
                        disabled={isSaving}
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                    >Dodaj placówkę</WButton>}
                </Grid>
            </Grid>
        </Box>
    );
};
export default AddVetClinicForm;
