import React from 'react';
import { Colors } from '../../styles/colors';
import { PulseLoader } from 'react-spinners';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Loading = (props: any) => {
    return (
        <LoadingWrapper {...props}>
            <PulseLoader color={Colors.vetGreen} />
        </LoadingWrapper>
    );
};
export default Loading;

// styles
const LoadingWrapper = styled(Box)({
    textAlign: 'center',
});