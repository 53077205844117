import React from 'react';
import Header from './Header';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Footer from './Footer';

const Layout = ({ children }: { children: any }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }}>
            <Header />
            <Container
                component="main"
                maxWidth="lg">
                {children}
            </Container>
            <Footer />
        </Box>
    );
}

export default Layout;