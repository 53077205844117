import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { H1, WBlueButton, WCard, WLink } from '../../styles/common';
import { CardContent, Grid, Breadcrumbs, Table, TableHead, TableCell, TableBody, TableRow, Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import http from '../../Api/http';
import { setMessage } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddVetClinicForm from './components/AddVetClinicForm';

const MyVetClinicsAdd = () => {
    const [vetClinics, setVetClinics] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(((state: any) => state.user));

    useEffect(() => {
        if (user && !user.userIsOwner) {
            // TODO return <Navigate to="" />;
        }
    }, []);

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-placowki`}>Moje placówki</WLink>
                            <WLink variant="small" to={`/moje-placowki`}>Dodaj placówkę</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="my-vet-clinics" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>
                            <CardContent>

                                <H1>Dodaj placówkę</H1>

                                <p>
                                    Wypełnij podstawowe dane placówki. Po jej dodaniu przejdziesz do możliwości wypełnienia danych szczegółowych.
                                </p>

                                <AddVetClinicForm />

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default MyVetClinicsAdd;
