import React from 'react';
import { Grow } from '@mui/material';
import Grid from '@mui/material/Grid';
import PlaceIcon from '@mui/icons-material/Place';
import Box from '@mui/material/Box';
import ReviewsIcon from '@mui/icons-material/Reviews';
import HelpIcon from '@mui/icons-material/Help';
import Container from '@mui/material/Container';
import styled from '@emotion/styled';
import { Colors } from '../../styles/colors';
import CardContent from '@mui/material/CardContent';
import { WCard } from '../../styles/common';

const InfoBoxes = () => {
    return (
        <Container maxWidth="lg">
            <Grow in={true}>
                <Grid container spacing={3} sx={{ mb: '30px' }}>
                    <Grid item md={4} sm={12}>
                        <InfoBox>
                            <InfoBoxInner>
                                <PlaceIcon fontSize={'large'} className="infoBoxIco" />
                                <Box>
                                    Wyszukaj placówkę dla zwierząt najbliżej Ciebie
                                </Box>
                            </InfoBoxInner>
                        </InfoBox>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <InfoBox>
                            <InfoBoxInner>
                                <ReviewsIcon fontSize={'large'} className="infoBoxIco" />
                                <Box>
                                    Oceń i dodaj opinię na temat odwiedzonej placówki
                                </Box>
                            </InfoBoxInner>
                        </InfoBox>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <InfoBox>
                            <InfoBoxInner>
                                <HelpIcon fontSize={'large'} className="infoBoxIco" />
                                <Box>
                                    Zadaj pytanie lekarzom weterynarii na nurtujący Cię temat
                                </Box>
                            </InfoBoxInner>
                        </InfoBox>
                    </Grid>
                </Grid>
            </Grow>
        </Container>
    );
};
export default InfoBoxes;

// styles
const InfoBox = styled(WCard)({
    backgroundColor: Colors.white,
    color: Colors.vetGreen,
    border: `solid 1px ${Colors.vetGreen}`,
    boxShadow: 'none',
});

const InfoBoxInner = styled(CardContent)({
    display: 'flex',
    alignItems: 'center',
    '.infoBoxIco': {
        marginRight: 10,
        color: Colors.vetGreen,
    },
});
