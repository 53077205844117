import React, { useCallback, useState } from 'react';
import { Box, Grid, FormLabel, Rating, TextField, Dialog, DialogContent, FormGroup } from '@mui/material';
import { H3, WButton, FormItemError } from '../../styles/common';
import LoginIcon from '@mui/icons-material/Login';
import CloseIcon from '@mui/icons-material/Close';
import RateReviewIcon from '@mui/icons-material/RateReview';
import styled from '@emotion/styled';
import { Colors } from '../../styles/colors';
import type TComment from '../../types/Review.types';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../Api/http';
import { setMessage } from '../../store/actions';
import { handleErrors } from '../../utils/helpers';
import LoginForm from '../LoginForm/LoginForm';

const initialReview: TComment = {
    content: '',
    improvement: '',
    service: '',
    rate: 0,
    author: {
        firstName: '',
        email: '',
    },
};

const AddReviewForm = ({ vetSlug, onClose, onReviewAdded }: { vetSlug: string, onClose: () => void, onReviewAdded: () => void }) => {
    const isAuth = useSelector((state: any) => state.isAuth);
    const [review, setReview] = useState<TComment>(initialReview);
    const [errors, setErrors] = useState<any>({});
    const [isLoginModalOpened, setIsLoginModalOpened] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleLoginModalClose = useCallback(() => {
        setIsLoginModalOpened(false);
    }, []);

    const onReviewChange = (field: string, value: any) => {
        const newReview: any = { ...review };
        newReview[field] = value;
        setReview(newReview);
    };

    const onReviewSubmit = (event: any) => {
        event.preventDefault();
        if (isLoginModalOpened) {
            return false;
        }

        setErrors({});

        const reviewData = {
            ...review,
            authorFirstName: review.author.firstName,
            authorEmail: review.author.email,
            vetSlug,
        };
        http().post(`/reviews${!isAuth ? '/nouser' : ''}`, reviewData)
            .then((response) => {
                let content = 'Opinia została dodana.';
                if (!isAuth) {
                    content = 'Opinia została dodana. Po zaakceptowaniu jej przez Administratora pojawi się w serwisie.';
                }
                dispatch(setMessage({
                    isOpen: true,
                    content,
                    type: 'success',
                }));
                setReview(initialReview);
                if (isAuth) {
                    onReviewAdded();
                }
                onClose();
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                // TODO: set loading
            });
    };

    return (
        <Box
            component="form"
            onSubmit={onReviewSubmit}
            sx={{
                borderBottom: `solid 1px ${Colors.lightGrey}`,
                pb: '20px',
                mb: '20px',
            }}
        >
            <H3>Dodaj opinię</H3>
            <WFormGroup2>
                <Box sx={{ display: 'flex', pt: '10px' }}>
                    <FormLabel sx={{ mr: '10px' }}>Ocena</FormLabel>
                    <Rating max={6} onChange={(ev, value) => onReviewChange('rate', value)} />
                </Box>
                {errors.rate && <FormItemError>{errors.rate}</FormItemError>}
            </WFormGroup2>

            <WFormGroup>
                <TextField
                    onChange={(ev) => onReviewChange('content', ev.target.value)}
                    required
                    fullWidth
                    label="Treść opinii"
                    size="small"
                    multiline
                    minRows="2"
                    error={errors.content && errors.content.length > 0}
                />
                {errors.content && <FormItemError>{errors.content}</FormItemError>}
            </WFormGroup>

            <WFormGroup>
                <TextField
                    onChange={(ev) => onReviewChange('service', ev.target.value)}
                    fullWidth
                    label="Opisz krótko zrealizowaną usługę (opcjonalne)"
                    size="small"
                />
            </WFormGroup>

            <WFormGroup>
                <TextField
                    onChange={(ev) => onReviewChange('improvement', ev.target.value)}
                    fullWidth
                    label="Co poprawiłbyś w naszej placówce? (opcjonalne)"
                    size="small"
                    multiline
                />
            </WFormGroup>

            {!isAuth &&
            <Grid
                container
                spacing={2}
                sx={{ pt: '20px', mb: '20px' }}
            >
                <Grid item sm={12}>
                    <ReviewLoginInfo>
                        <span>Podaj swoje imię oraz adres e-mail lub</span>
                        <WButton
                            onClick={() => setIsLoginModalOpened(true)}
                            variant="outlined"
                            size="small"
                            startIcon={<LoginIcon fontSize="small" />}
                        >Zaloguj się</WButton>
                    </ReviewLoginInfo>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onReviewChange('author', { ...review.author, firstName: ev.target.value })}
                        required={!isAuth}
                        fullWidth
                        label="Imię"
                        size="small"
                        error={errors.authorFirstName && errors.authorFirstName.length > 0}
                    />
                    {errors.authorFirstName && <FormItemError>{errors.authorFirstName}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onReviewChange('author', { ...review.author, email: ev.target.value })}
                        required={!isAuth}
                        fullWidth
                        label="Adres e-mail"
                        type="email"
                        size="small"
                        error={errors.authorEmail && errors.authorEmail.length > 0}
                    />
                    {errors.authorEmail && <FormItemError>{errors.authorEmail}</FormItemError>}
                </Grid>
            </Grid>}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                    <WButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        startIcon={<RateReviewIcon />}
                    >Dodaj opinię</WButton>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <WButton
                        onClick={onClose}
                        fullWidth
                        variant="outlined"
                        size="large"
                    >Anuluj</WButton>
                </Grid>
            </Grid>

            <Dialog
                open={isLoginModalOpened}
                onClose={handleLoginModalClose}
                PaperProps={{
                    sx: {
                        position: 'fixed',
                        top: '100px',
                    },
                }}
            >
                <DialogContent>
                    <CloseDialogButton>
                        <CloseIcon
                            onClick={handleLoginModalClose}
                            fontSize="medium"
                        />
                    </CloseDialogButton>
                    <LoginForm
                        onLogin={handleLoginModalClose}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};
export default AddReviewForm;

// styles
export const ReviewLoginInfo = styled(FormLabel)({
    span: {
        marginRight: 10,
    },
});

export const CloseDialogButton = styled(Box)({
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
});

export const WFormGroup = styled(FormGroup)({
    marginBottom: 10,
});

export const WFormGroup2 = styled(FormGroup)({
    marginBottom: 20,
});