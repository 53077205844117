import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import Loading from '../../../components/helpers/Loading';
import { FormGroup, WButton, H5, SwitchLabel } from '../../../styles/common';
import { useDispatch } from 'react-redux';
import { daysNames } from '../../../config/constants';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Colors } from '../../../styles/colors';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import type { OpeningHour } from '../../../types/types';
import { FontSize } from '../../../styles/typography';

const EditOpeningHours = ({ vetSlug }: { vetSlug: string }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openingHours, setOpeningHours] = useState<OpeningHour[]>([]);

    const [errors, setErrors] = useState<any>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const user = useSelector(((state: any) => state.user));
    // TODO: check user type to redirect if now owner

    useEffect(() => {
        if (vetSlug) {
            loadOpeningHours();
        }
    }, [vetSlug]);

    const loadOpeningHours = () => {
        setIsLoading(true);
        http().get(`/opening-hours/${vetSlug}`)
            .then(({ data }: { data: OpeningHour[] }) => {
                if (Array.isArray(data) && data.length > 0) {
                    setOpeningHours(data.map((item) => ({
                        ...item,
                        tmpId: Math.random() * 100,
                    })));
                } else {
                    generateEmptyHours();
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const generateEmptyHours = () => {
        const emptyOpeningHours = [];
        for (let d = 1; d <= 7; d++) {
            emptyOpeningHours.push({
                tmpId: Math.random() * 100,
                dayId: d,
                startHour: 8,
                startMinute: 0,
                endHour: 16,
                endMinute: 0,
                isClosed: d === 6 || d === 7,
            });
        }
        setOpeningHours(emptyOpeningHours);
    };

    const addNewOpeningHour = () => {
        const newOpeningHours = [...openingHours];
        newOpeningHours.push({
            tmpId: Math.random() * 100,
            dayId: 1,
            startHour: 8,
            startMinute: 0,
            endHour: 16,
            endMinute: 0,
            isClosed: false,
        });
        setOpeningHours(newOpeningHours);
    };

    const onFieldChange = (field: string, val: any, index: number) => {
        const newOpeningHours: OpeningHour[] = [...openingHours];
        if (field === 'timeStart') {
            newOpeningHours[index].startHour = val.getHours();
            newOpeningHours[index].startMinute = val.getMinutes();
        } else if (field === 'timeEnd') {
            newOpeningHours[index].endHour = val.getHours();
            newOpeningHours[index].endMinute = val.getMinutes();
        } else if (field === 'dayId') {
            newOpeningHours[index].dayId = val;
        } else if (field === 'isClosed') {
            newOpeningHours[index].isClosed = val;
        }
        setOpeningHours(newOpeningHours);
    };

    const getOpeningValue = (opening: OpeningHour, timeType: 'timeStart' | 'timeEnd') => {
        let h = 0;
        let m = 0;
        if (timeType === 'timeStart') {
            h = opening.startHour;
            m = opening.startMinute;
        } else if (timeType === 'timeEnd') {
            h = opening.endHour;
            m = opening.endMinute;
        }
        return new Date(0, 0, 0, h, m, 0);
    };

    const submitChanges = (ev: any) => {
        ev.preventDefault();
        setErrors({});
        setIsSaving(true);

        const promises = [];
        for (let p = 0; p < openingHours.length; p++) {
            if (openingHours[p].id) {
                promises.push(http().patch(`/opening-hours/${vetSlug}/${openingHours[p].id}`, openingHours[p]));
            } else {
                promises.push(http().post(`/opening-hours/${vetSlug}`, openingHours[p]));
            }
        }
        Promise.all(promises)
            .then(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Godziny otwarcia placówki zostały zapisane.',
                    type: 'success',
                }));
                loadOpeningHours();
            })
            .catch((errors) => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Wystąpił błąd w zapisie, spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const deleteOpeningHour = (opening: OpeningHour) => {
        const newOpeningHours: OpeningHour[] = openingHours.filter(
            (item) => item.tmpId !== opening.tmpId
        );
        if (opening.id) {
            setIsLoading(true);
            http().delete(`/opening-hours/${vetSlug}/${opening.id}`)
                .then(() => {
                    setOpeningHours(newOpeningHours);
                })
                .catch(() => {
                    dispatch(setMessage({
                        isOpen: true,
                        content: 'Wystąpił błąd podczas usuwania, spróbuj ponownie.',
                        type: 'error',
                    }));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setOpeningHours(newOpeningHours);
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                <Box sx={{ mb: '30px', fontSize: FontSize.text }}>
                    Poniżej możesz wypełnić godziny pracy placówki dla poszczególnych dni tygodnia.
                    <br />
                    Możesz dodać kilka przedziałów czasowych dla jednego dnia używając przyciku "Dodaj godziny".
                    <br />
                    Jeżeli dany przedział czasowy nie został jeszcze zapisany pojawi się przy nim ikonka <FiberNewIcon fontSize="small" sx={{ color: Colors.vetBlue }} />
                </Box>

                {isLoading && <Loading />}

                {!isLoading && openingHours.length > 0 && <Box>
                    {openingHours.map((opening: OpeningHour, key: number) => {
                        return (
                            <FormGroup
                                key={key}
                                container
                                spacing={2}
                            >
                                <Grid item md={3} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Dzień tygodnia</InputLabel>
                                        <Select
                                            onChange={(ev) => onFieldChange('dayId', ev.target.value, key)}
                                            disabled={isSaving}
                                            required={true}
                                            fullWidth
                                            label="Dzień tygodnia"
                                            size="small"
                                            value={opening.dayId}
                                        >
                                            {daysNames.map((day, k: number) => {
                                                return (
                                                    <MenuItem value={day.id} key={k}>
                                                        {day.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TimePicker
                                        label={'Początek'}
                                        onChange={(value) => onFieldChange('timeStart', value, key)}
                                        value={getOpeningValue(opening, 'timeStart')}
                                        disabled={!!opening.isClosed || isSaving}
                                        ampm={false}
                                        minTime={new Date(0, 0, 0, 0, 0)}
                                        maxTime={new Date(0, 0, 0, 23, 59)}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                    {/*{errors.name && <FormItemError>{errors.name}</FormItemError>}*/}
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TimePicker
                                        label={'Koniec'}
                                        onChange={(value) => onFieldChange('timeEnd', value, key)}
                                        value={getOpeningValue(opening, 'timeEnd')}
                                        disabled={!!opening.isClosed || isSaving}
                                        ampm={false}
                                        minTime={new Date(0, 0, 0, 0, 0)}
                                        maxTime={new Date(0, 0, 0, 23, 59)}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                    {/*{errors.name && <FormItemError>{errors.name}</FormItemError>}*/}
                                </Grid>
                                <Grid item md={3} xs={12} sx={{ position: 'relative' }}>
                                    <SwitchLabel sx={{ fontSize: FontSize.text }}>
                                        <Switch
                                            disabled={isSaving}
                                            onChange={(ev: any) => onFieldChange('isClosed', ev.target.checked, key)}
                                            checked={!!opening.isClosed}
                                        /> nieczynne
                                    </SwitchLabel>
                                </Grid>
                                <Grid item md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <DeleteItemLink onClick={() => deleteOpeningHour(opening)} />
                                </Grid>
                                <Grid item md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {!opening.id && <IconNewItem>
                                        <FiberNewIcon fontSize="small" />
                                    </IconNewItem>}
                                </Grid>
                            </FormGroup>
                        );
                    })}
                </Box>}

                {!isLoading && <Grid container spacing={2}>
                    <Grid item md={3}>
                        <WButton
                            disabled={isSaving}
                            variant="outlined"
                            fullWidth
                            onClick={addNewOpeningHour}
                            startIcon={<AddIcon />}
                        >Dodaj godziny</WButton>
                    </Grid>
                    <Grid item md={4} />
                    <Grid item md={3} xs={12}>
                        {isSaving && <Loading />}

                        {!isSaving && <WButton
                            disabled={isSaving}
                            onClick={submitChanges}
                            fullWidth
                            variant="contained"
                            size="large"
                        >Zapisz zmiany</WButton>}
                    </Grid>
                </Grid>}

            </Box>
        </LocalizationProvider>
    );
};
export default EditOpeningHours;

// styles
const IconNewItem = styled(Box)({
    color: Colors.vetBlue,
});

const DeleteItemLink = styled(DeleteIcon)({
    color: Colors.lightBlack,
    cursor: 'pointer',
});
