import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Layout/Header';
import { Colors } from '../../styles/colors';
import SearchBox from '../../components/Search/SearchBox';
import CitiesBox from './CitiesBox';
import Footer from '../../components/Layout/Footer';
// import SpecializationsBox from './SpecializationsBox';
import InfoBoxes from './InfoBoxes';
import ReviewsBox from './ReviewsBox';

const Home = () => {
    return (
        <HelmetProvider>
            <HomeLayout>
                <Helmet>
                    <title>Znajdź placówki z usługami dla zwierząt w Twojej okolicy - weteo.pl</title>
                    <meta name="description" content="Znajdź polecane placówki dla zwierząt w Twojej okolicy. Sprawdź opinie o placówkach dla zwierząt w Polsce." />
                </Helmet>
                <Header />
                <HomeBoxStyled sx={{
                    backgroundImage: {
                        sm: 'url(/img/img_home_sm.png)',
                        xs: 'url(/img/img_home_sm.png)',
                        md: 'none',
                    },
                    backgroundPosition: {
                        sm: 'center -160px',
                        xs: 'center -160px',
                    },
                    backgroundSize: 'auto 700px',
                    backgroundRepeat: 'no-repeat',
                    minHeight: {
                        sm: '400px',
                    },
                }}>
                    <Container
                        maxWidth="lg"
                        sx={{
                            padding: {
                                xs: 0,
                            },
                        }}
                    >
                        <HomeContainer>
                            <Grid container>
                                <Grid item sm={1} sx={{ display: { md: 'none', sm: 'block', xs: 'none' } }} />
                                <Grid
                                    item
                                    md={6}
                                    sm={10}
                                    xs={12}
                                >
                                    <SearchBox />
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    sx={{
                                        display: {
                                            sm: 'none',
                                            xs: 'none',
                                            md: 'block',
                                        },
                                        backgroundImage: 'url(/img/img_home.png)',
                                        backgroundPosition: 'left bottom',
                                        backgroundSize: 'auto 500px',
                                        backgroundRepeat: 'no-repeat',
                                        minHeight: '420px',
                                    }}
                                />
                            </Grid>
                        </HomeContainer>
                    </Container>

                </HomeBoxStyled>

                <InfoBoxes />

                <ReviewsBox />

                <CitiesBox />

                {/*<SpecializationsBox />*/}

                <Footer />

            </HomeLayout>
        </HelmetProvider>
    );
};
export default Home;

// styles
const HomeBoxStyled = styled(Box)({
    backgroundColor: Colors.white,
    marginBottom: 30,
    borderBottom: `solid 1px ${Colors.grey}`,
});

const HomeContainer = styled(Box)({
    position: 'relative',
    display: 'block',
    boxSizing: 'border-box',
});

const HomeLayout = styled('div')({
    backgroundColor: '#fbfbfb',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});
