import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { H3, WCard, WLink } from '../../styles/common';
import http from '../../Api/http';
import { Container, Grid, CardContent, Grow, Box } from '@mui/material';
import type TComment from '../../types/Review.types';
import ReviewsList from '../../components/Comments/ReviewsList';
import Loading from '../../components/helpers/Loading';
import { Colors } from '../../styles/colors';
import { FontSize } from '../../styles/typography';

const ReviewsBox = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reviews, setReviews] = useState<TComment[]>([]);
    // const [questions, setQuestions] = useState<any[]>([]);
    const [vets, setVets] = useState<any[]>([]);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            http().get('/home/reviews'),
            http().get('/home/vets'),
            // http().get('/home/questions'),
        ])
            .then(([reviews, items]: [reviews: any, items: any]) => {
                setReviews(reviews.data);
                setVets(items.data);
                // setQuestions(items.data);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Container maxWidth="lg">
            {reviews.length > 0 &&
            <Grid container spacing={2} sx={{ mb: '30px' }}>
                <Grid item md={6} sm={12} xs={12}>
                    {isLoading && <Loading />}
                    <Grow in={!isLoading && reviews.length > 0}>
                        <WCard>
                            <CardContent>
                                <HomeReviewsHeader><H3>Ostatnio dodane opinie</H3></HomeReviewsHeader>
                                <ReviewsList
                                    reviews={reviews}
                                    vetLink={true}
                                    hideCreatedAt={true}
                                />
                            </CardContent>
                        </WCard>
                    </Grow>
                </Grid>
                <Grid item md={6}>
                    {isLoading && <Loading />}
                    {/*<Grow in={!isLoading && questions.length > 0}>*/}
                    {/*    <WCard>*/}
                    {/*        <CardContent>*/}
                    {/*            <HomeReviewsHeader><H3>Pytania z najnowszymi odpowiedziami</H3></HomeReviewsHeader>*/}
                    {/*            {questions.map((question: any, ind: number) => {*/}
                    {/*                return (*/}
                    {/*                    <Box*/}
                    {/*                        key={ind}*/}
                    {/*                        sx={{*/}
                    {/*                            borderBottom: ind + 1 < questions.length ? `solid 1px ${Colors.lightGrey}` : 'none',*/}
                    {/*                            mb: ind + 1 < questions.length ? '10px' : 0,*/}
                    {/*                            pb: ind + 1 < questions.length ? '10px' : 0,*/}
                    {/*                            fontSize: FontSize.text,*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        {question.content}...*/}
                    {/*                        <br />*/}
                    {/*                        <WLink to={`/pytania-do-weterynarza/${question.slug}`}>Sprawdź odpowiedzi ({question.answersCount})</WLink>*/}
                    {/*                    </Box>*/}
                    {/*                );*/}
                    {/*            })}*/}
                    {/*        </CardContent>*/}
                    {/*    </WCard>*/}
                    {/*</Grow>*/}
                    <Grow in={!isLoading && vets.length > 0}>
                        <WCard>
                            <CardContent>
                                <HomeReviewsHeader><H3>Ostatnio dodane miejsca</H3></HomeReviewsHeader>
                                {vets.map((vet: any, ind: number) => {
                                    return (
                                        <Box
                                            key={ind}
                                            sx={{
                                                borderBottom: ind + 1 < vets.length ? `solid 1px ${Colors.lightGrey}` : 'none',
                                                mb: ind + 1 < vets.length ? '10px' : 0,
                                                pb: ind + 1 < vets.length ? '10px' : 0,
                                            }}
                                        >
                                            <WLink to={`/weterynarz/${vet['City.slug']}/${vet.slug}`}>{vet.name}</WLink>
                                            <br />
                                            {vet.street}, {vet.zip} {vet['City.name']}
                                        </Box>
                                    );
                                })}
                            </CardContent>
                        </WCard>
                    </Grow>
                </Grid>
            </Grid>}
        </Container>
    );
};

export default ReviewsBox;

// styles
const HomeReviewsHeader = styled(Box)({
    marginBottom: 10,
});
