import React, { useRef } from 'react';
import { H1, WButton, WLink } from '../../styles/common';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import RemoveIcon from '@mui/icons-material/Remove';
import FacebookLoginButton from '../FacebookLoginButton/FacebookLoginButton';
import Grid from '@mui/material/Grid';
import http from '../../Api/http';
import { login, setMessage } from '../../store/actions';
import { useDispatch } from 'react-redux';

const LoginForm = ({ label, onLogin }: { label?: string, onLogin?: () => void }) => {
    const emailInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch();

    const loginHandler = (event: any) => {
        event.preventDefault();

        const email = emailInputRef?.current?.value;
        const password = passwordInputRef?.current?.value;

        http().post('/auth/login', { email, password })
            .then(({ data }) => {
                if (data && data.token) {
                    dispatch(login(data.token));
                    if (onLogin) {
                        onLogin();
                    }
                } else {
                    dispatch(setMessage({
                        isOpen: true,
                        content: 'Wystąpił błąd podczas logowania. Spróbuj ponownie lub skontaktuj się z Administratorem.',
                        type: 'error',
                    }));
                }
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Niepoprawny email lub hasło',
                    type: 'error',
                }));
            });
    };

    return (
        <>
            <H1>{label || 'Zaloguj się'}</H1>
            <Box
                component="form"
                onSubmit={(event: any) => loginHandler(event)}
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    inputRef={emailInputRef}
                    required
                    fullWidth
                    id="email"
                    label="Adres e-mail"
                    name="email"
                    autoComplete="email"
                    type="email"
                    autoFocus
                    size="small"
                />
                <TextField
                    margin="normal"
                    inputRef={passwordInputRef}
                    required
                    fullWidth
                    name="password"
                    label="Hasło"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    size="small"
                />

                <WButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Zaloguj się
                </WButton>

                {/*<Box*/}
                {/*    sx={{*/}
                {/*        margin: '0 auto 15px',*/}
                {/*        display: 'flex',*/}
                {/*        alignItems: 'center',*/}
                {/*        justifyContent: 'center',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <RemoveIcon/> lub <RemoveIcon />*/}
                {/*</Box>*/}

                {/*<FacebookLoginButton userType={10} />*/}

                <Grid container>
                    <Grid item xs/>
                    <Grid item>
                        <WLink to={'/rejestracja'}>
                            Nie masz konta? Zarejestruj się
                        </WLink>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default LoginForm;
