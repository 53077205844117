import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import http from '../../Api/http';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { WCard, H1, WBlueButton, InfoSmall, WLink } from '../../styles/common';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Loading from '../../components/helpers/Loading';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { setMessage } from '../../store/actions';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { BackLink } from '../VetsList/VetsList';

const AssignOwner = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [vetDetails, setVetDetails] = useState<any>(null);
    const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (params.vetSlug) {
            loadVetDetails(params.vetSlug);
        }
    }, []);

    const loadVetDetails = (slug: string) => {
        setLoading(true);
        http().get(`/vets/${slug}`)
            .then(({ data }) => {
                if (!data.ownerAssigned) {
                    setVetDetails(data);
                } else {
                    dispatch(setMessage({
                        isOpen: true,
                        content: 'Ta placówka jest już przypisana do użytkownika. Jeżeli uważasz, że to błąd - skontaktuj się z nami poprzez zakładkę Kontakt.',
                        type: 'error',
                    }));
                    navigate('/');
                }
            })
            .catch(() => {
                return dispatch(setMessage({
                    isOpen: true,
                    content: 'Wystąpił błąd, spróbuj ponownie za chwilę.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addVetOwnerRequest = () => {
        setLoading(true);
        http().post(`/vet-owner/request/${params.vetSlug}`)
            .then(() => {
                setConfirmSuccess(true);
            })
            .catch(() => {
                return dispatch(setMessage({
                    isOpen: true,
                    content: 'Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z administratorem. Możliwe, że placówka jest już przypisana do właściciela.',
                    type: 'error',
                }));
            })
            .finally(() => setLoading(false));
    };

    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{'Jestem właścicielem tej placówki - weteo.pl'}</title>
                    <meta name="description" content="Jestem właścicielem tej placówki w serwisie weteo.pl" />
                </Helmet>

                <Box sx={{
                    mt: '20px',
                    mb: '20px',
                }}>
                    <Grid container>
                        <Grid item md={3} sm={0} />
                        <Grid item md={6} sm={12}>
                            {loading && <Loading sx={{ pt: '50px' }} />}
                            {!loading && vetDetails && vetDetails.slug && <WCard>
                                <CardContent>
                                    <Box sx={{ mb: '15px' }}>
                                        <H1>Zarządzaj tą placówką</H1>
                                    </Box>

                                    <strong>{vetDetails.name}</strong>
                                    <br />
                                    <strong>{vetDetails.street}</strong>
                                    <br />
                                    <strong>{vetDetails.zip} {vetDetails['City.name']}</strong>

                                    {confirmSuccess && <Box sx={{ pt: '10px' }}>
                                        <p>
                                            Prośba o przypisanie placówki do Twojego konta została wysłana. Po weryfikacji zgłoszenia prześlemy potwierdzenie na Twój adres e-mail.
                                        </p>
                                        <BackLink to="/"><KeyboardBackspaceIcon /> <span>Przejdź do strony głównej</span></BackLink>
                                    </Box>}

                                    {!confirmSuccess && <Box>
                                        <p>
                                            Oświadczam, że jestem właścicielem tej placówki i chcę przypisać do niej swoje konto użytkownika, aby móc nią zarządzać w serwisie weteo.pl
                                        </p>
                                        <Box>
                                            <WBlueButton
                                                disabled={user && !user.userIsOwner}
                                                variant="contained"
                                                onClick={addVetOwnerRequest}
                                            >Wyślij prośbę o dostęp do edycji</WBlueButton>
                                            {user && !user.userIsOwner && <InfoSmall sx={{ pt: '10px' }}>
                                                Nie możesz wykonać tej czynności, ponieważ Twoje konto nie zostało zarejestrowane jako "właściciel placówki". Możesz to zmienić w ustawieniach swojego profilu:
                                                <br />
                                                <strong><WLink to="/profil-i-ustawienia">Profil i ustawienia</WLink></strong>
                                            </InfoSmall>}
                                        </Box>
                                    </Box>}
                                </CardContent>
                            </WCard>}
                        </Grid>
                    </Grid>
                </Box>
            </Layout>
        </HelmetProvider>
    );
}
export default AssignOwner;
