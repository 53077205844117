import React, { useEffect, useState } from 'react';
import { Box, Grid, CardContent, Grow } from '@mui/material';
import { OpeningHour } from '../../../types/types';
import { H3, WCard } from '../../../styles/common';
import { getDayName } from '../../../utils/helpers';
import { FontSize } from '../../../styles/typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styled from '@emotion/styled';

const OpeningHours = ({ items }: { items: OpeningHour[] }) => {
    const [todayId, setTodayId] = useState<number>(0);
    const [openingHours, setOpeningHours] = useState<any>([]);

    useEffect(() => {
        const today = new Date().getDay();
        setTodayId(today === 0 ? 7 : today + 1);

        const newOpeningHours: any = {};
        const tmpOpeningHours: any = [];
        for (let i = 0; i < items.length; i++) {
            if (newOpeningHours[items[i].dayId] && newOpeningHours[items[i].dayId].hours) {
                newOpeningHours[items[i].dayId].hours = `${newOpeningHours[items[i].dayId].hours}, ${formatHour(items[i].startHour, items[i].startMinute)} - ${formatHour(items[i].endHour, items[i].endMinute)}`;
            } else {
                newOpeningHours[items[i].dayId] = {
                    dayId: items[i].dayId,
                    dayName: getDayName(items[i].dayId).name,
                    isClosed: items[i].isClosed,
                    hours: `${formatHour(items[i].startHour, items[i].startMinute)} - ${formatHour(items[i].endHour, items[i].endMinute)}`,
                };
            }
        }
        const keys = Object.keys(newOpeningHours);
        for (let k = 1; k <= keys.length; k++) {
            tmpOpeningHours.push(newOpeningHours[k]);
        }
        setOpeningHours(tmpOpeningHours);
    }, []);

    const formatHour = (hh: number, mm: number): string => {
        const h = hh > 9 ? hh : `0${hh}`;
        const m = mm > 9 ? mm : `0${mm}`;
        return `${h}:${m}`;
    };

    return (
        <Grow in={true}>
            <WCard sx={{ mb: '20px' }}>
                <CardContent>
                    <OpeningHoursHeader>
                        <AccessTimeIcon /> <H3>Godziny otwarcia</H3>
                    </OpeningHoursHeader>
                    {openingHours.map((item: any, k: number) => {
                        return (
                            <Grid
                                container
                                spacing={2}
                                key={k}
                                sx={{
                                    mb: '5px',
                                    fontSize: FontSize.text,
                                    fontWeight: item.dayId === todayId ? 500 : 'normal',
                                }}
                            >
                                <Grid item md={5} sm={5} xs={5}>
                                    {item.dayName}
                                </Grid>
                                <Grid item md={7} sm={7} xs={7}>
                                    {!!item.isClosed && <Box>nieczynne</Box>}
                                    {!item.isClosed && <Box>{item.hours}</Box>}
                                </Grid>
                            </Grid>
                        );
                    })}
                </CardContent>
            </WCard>
        </Grow>
    );
};
export default OpeningHours;

//styles
const OpeningHoursHeader = styled(Box)({
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    svg: {
        marginRight: 5,
    },
});
