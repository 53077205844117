import React, { useCallback, useEffect, useState } from 'react';
import { H3, WButton, WCard } from '../../styles/common';
import { Box, CardContent, Collapse, Grid } from '@mui/material';
import { PulseLoader } from 'react-spinners';
import { Colors } from '../../styles/colors';
import type TComment from '../../types/Review.types';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReviewsIcon from '@mui/icons-material/Reviews';
import styled from '@emotion/styled';
import http from '../../Api/http';
import AddReviewForm from './AddReviewForm';
import ReviewsList from './ReviewsList';

const Reviews = ({ vetSlug }: { vetSlug: string }) => {
    const [reviews, setReviews] = useState<TComment[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAddReviewForm, setIsAddReviewForm] = useState<boolean>(false);

    useEffect(() => {
        reloadReviews(vetSlug);
    }, [vetSlug]);

    const reloadReviews = useCallback((slug: string) => {
        setIsLoading(true);
        http().get(`/reviews/${slug}`)
            .then(({ data }) => {
                setReviews(data.content);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const handleReviewFormOnClose = useCallback(() => {
        setIsAddReviewForm(false);
    }, []);

    return (
        <WCard sx={{ mb: '20px' }}>
            <CardContent>
                <Collapse in={isAddReviewForm}>
                    <AddReviewForm
                        vetSlug={vetSlug}
                        onClose={handleReviewFormOnClose}
                        onReviewAdded={() => reloadReviews(vetSlug)}
                    />
                </Collapse>

                <AllReviewsHeader container>
                    <Grid item md={8} sm={7} xs={6}>
                        <ReviewsHeader>
                            <ReviewsIcon /> <H3>Opinie</H3>
                        </ReviewsHeader>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={5}
                        xs={6}
                        sx={{
                            textAlign: 'right',
                        }}
                    >
                        {!isAddReviewForm && <WButton
                            onClick={() => setIsAddReviewForm(true)}
                            variant="contained"
                            size="small"
                            startIcon={<RateReviewIcon />}
                        >Dodaj opinię</WButton>}
                    </Grid>
                </AllReviewsHeader>
                {isLoading && <PulseLoader color={Colors.vetGreen} />}

                {!isLoading && reviews.length > 0 && <ReviewsList reviews={reviews}/>}

                {!isLoading && reviews.length === 0 &&
                <NoReviewsBox>
                    <div>Nikt jeszcze nie dodał opinii dla tej placówki. Możesz to zmienić</div>
                    <SentimentSatisfiedAltIcon fontSize="small" />
                </NoReviewsBox>}
            </CardContent>
        </WCard>
    );
};
export default Reviews;

// styles
const AllReviewsHeader = styled(Grid)({
    marginBottom: 20,
});

const NoReviewsBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    svg: {
        marginLeft: 5,
    },
});

const ReviewsHeader = styled(Box)({
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    svg: {
        marginRight: 5,
    },
});
