import http from '../Api/http';

export type IMessage = {
    isOpen: boolean;
    content: string;
    type: 'error' | 'success' | 'warning' | 'info' | null;
}

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_MESSAGE = 'SET_MESSAGE';

export const login = (token: string) => {
    return (dispatch: any) => {
        localStorage.setItem('wp-token', token);
        http().get('/auth/current')
            .then((response) => {
                localStorage.setItem('wp-user', JSON.stringify(response.data));
                dispatch({
                    type: LOGIN,
                    isAuth: true,
                    user: response.data,
                    token,
                    currentCalled: true,
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN,
                    isAuth: false,
                    user: null,
                    token: null,
                    currentCalled: true,
                });
            });
    };
};

export const logout = () => {
    return (dispatch: any) => {
        localStorage.removeItem('wp-user');
        localStorage.removeItem('wp-token');
        dispatch({ type: LOGOUT });
    };
};

export const setMessage = (message?: IMessage) => {
    return (dispatch: any) => {
        dispatch({ type: SET_MESSAGE, message})
    };
};