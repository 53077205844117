import { provinces, daysNames } from '../config/constants';
import type { Dict } from '../types/types';

export const getProvinceById = (id: number): Dict => {
    return provinces.find((p: Dict) => p.id === id) || { id: 0, name: '-', slug: '-'};
};

export const getProvinceBySlug = (slug: string): Dict => {
    return provinces.find((p: Dict) => p.slug === slug) || { id: 0, name: '-', slug: '-'};
};

export const getDayName = (dayId: number): Dict => {
    return daysNames.find((item) => item.id === dayId) || { id: 0, name: '-' };
}

export const formatDate = (datetime?: string): string => {
    if (!datetime) {
        return '';
    }
    const date = datetime.split('T')[0];
    const parts = date.split('-');
    return `${parts[2]}.${parts[1]}.${parts[0]}`;
}

export const handleErrors = (errorsResponse: any, callback: any) => {
    if (errorsResponse && errorsResponse.response && errorsResponse.response.data && Array.isArray(errorsResponse.response.data.errors)) {
        const errorsObj: any = {};
        for (let i = 0; i < errorsResponse.response.data.errors.length; i++) {
            const field = errorsResponse.response.data.errors[i].param;
            errorsObj[field] = errorsResponse.response.data.errors[i].msg || '';
        }
        callback(errorsObj);
    }
};