import React from 'react';
import TUser from '../../types/User.types';
import { Avatar as MAvatar } from '@mui/material';

const Avatar = ({ user, sx }: { user: TUser, sx?: any }) => {
    const stringToColor = (string: string) => {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }

    const stringAvatar = (user: TUser) => {
        if (!user.lastName) {
            user.lastName = user.firstName;
        }
        return {
            sx: {
                ...sx,
                bgcolor: stringToColor(`${user.firstName} ${user.lastName}`),
            },
            children: `${user.firstName.slice(0, 1).toUpperCase()}${user.lastName.slice(0, 1).toUpperCase()}`,
        };
    }

    return (
        <MAvatar {...stringAvatar(user)} />
    );
};
export default Avatar;