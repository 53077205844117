import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions';
import Layout from '../../components/Layout/Layout';
import http from '../../Api/http';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { WCard, WLink } from '../../styles/common';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Loading from '../../components/helpers/Loading';

const AuthConfirmEmail = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [confirmError, setConfirmError] = useState<boolean>(false);
    const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params = useParams();
    const isAuth = useSelector((state: any) => state.isAuth);

    useEffect(() => {
        if (params.userId && params.hash && !isAuth) {
            confirmEmailHandler();
        }
    }, [params, isAuth]);

    const confirmEmailHandler = () => {
        setLoading(true);
        http().post('/auth/confirm-email', { ...params })
            .then(({ data }) => {
                if (data && data.confirmed && data.token) {
                    dispatch(login(data.token));
                    setConfirmSuccess(true);
                }
            })
            .catch(() => {
                setConfirmError(true);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Layout>
            <Box sx={{
                mt: '20px',
                mb: '20px',
            }}>
                <Grid container>
                    <Grid item md={3} sm={0} />
                    <Grid item md={6} sm={12}>
                        {loading && <Loading sx={{ pt: '50px' }} />}
                        {!loading && <WCard>
                            {confirmSuccess && <CardContent>
                                <p>
                                    Twoje konto zostało aktywowane! Możesz korzystać z serwisu.
                                </p>
                                <p>
                                    Zostałeś automatycznie zalogowany.
                                </p>
                            </CardContent>}
                            {confirmError && <CardContent>
                                <p>
                                    Nie można aktywować konta, spróbuj ponownie lub skontaktuj się z administratorem - <WLink to={'/kontakt'}>Kontakt</WLink>
                                </p>
                            </CardContent>}
                        </WCard>}
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
}
export default AuthConfirmEmail;
