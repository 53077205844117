import React from 'react';
import { Box, Grid, Rating } from '@mui/material';
import { Colors } from '../../styles/colors';
import type TComment from '../../types/Review.types';
import Avatar from '../Avatar/Avatar';
import styled from '@emotion/styled';
import { formatDate } from '../../utils/helpers';
import { WLink } from '../../styles/common';

const ReviewsList = (
    { reviews = [], vetLink = false, hideCreatedAt = false }:
        { reviews: TComment[], vetLink?: boolean, hideCreatedAt?: boolean }
) => {
    const getVetLink = (vet: any) => {
        if (vet && vet.slug && vet.city && vet.city.slug) {
            return `/weterynarz/${vet.city.slug}/${vet.slug}`;
        }
        return null;
    };

    return (
        <Box>
            {reviews.map((review: TComment, key: number) => {
                return (
                    <ReviewBox key={key} isLast={reviews.length === key + 1}>
                        {vetLink && getVetLink(review.vet) && <ReviewVetLink>
                            <WLink to={getVetLink(review.vet)}>{review.vet.name}</WLink>
                        </ReviewVetLink>}
                        <ReviewHeader container>
                            <ReviewHeaderAuthor item xs={8}>
                                <Avatar user={review.author} />
                                <AuthorName>{review.author.firstName}</AuthorName>
                            </ReviewHeaderAuthor>
                            <ReviewHeaderRate item xs={4}>
                                <Rating readOnly value={review.rate} max={6} />
                            </ReviewHeaderRate>
                        </ReviewHeader>
                        <Box>
                            {review.content}
                        </Box>
                        {!hideCreatedAt && <ReviewCreatedAt>Utworzono: {formatDate(review.createdAt)}</ReviewCreatedAt>}
                    </ReviewBox>
                );
            })}
        </Box>
    );
};
export default ReviewsList;

const ReviewBox = styled(Box)(({ isLast = false }: { isLast?: boolean }) => ({
    marginBottom: isLast? 0 : 20,
    paddingBottom: isLast ? 0 : 20,
    borderBottom: isLast ? 'none' : `solid 1px ${Colors.lightGrey}`,
    fontSize: 14,
}));

const ReviewHeader = styled(Grid)({
    marginBottom: 10,
});

const AuthorName = styled(Box)({
    fontWeight: 'bold',
});

const ReviewHeaderAuthor = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    '.MuiAvatar-root': {
        marginRight: 10,
    },
});

const ReviewVetLink = styled(Box)({
    marginBottom: 10,
});

const ReviewHeaderRate = styled(Grid)({
    justifyContent: 'right',
    display: 'flex',
    alignItems: 'center',
});

const ReviewCreatedAt = styled(Box)({
    fontSize: 12,
    color: Colors.grey,
    paddingTop: 5,
    textAlign: 'right',
});
