import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { WButton } from '../../styles/common';
import FileUploadItem from './FileUploadItem';

const FilesUpload = (
    { maxFilesCount = 1, multiFile = false, fileType = 1, onFileUploaded }:
        { maxFilesCount?: number, multiFile?: boolean, fileType?: number, onFileUploaded?: () => void }
) => {
    const [filesCount, setFilesCount] = useState<number>(1);
    const [uploadStarted, setUploadStarted] = useState<any>({});
    const [fileSelected, setFileSelected] = useState<boolean>(false);

    useEffect(() => {
        const newUploadStarted: any = {};
        for (let i = 0; i < filesCount; i++) {
            newUploadStarted[i] = 0;
        }
        setUploadStarted(newUploadStarted);
    }, [filesCount]);

    const addNextFile = () => {
        if ((filesCount + 1) <= maxFilesCount && multiFile) {
            setFilesCount(filesCount + 1);
        }
    };

    const getFilesIds = () => {
        return Array.from(Array(filesCount).keys());
    };

    const uploadFiles = () => {
        const newUploadStarted: any = {};
        for (let i = 0; i < filesCount; i++) {
            newUploadStarted[i] = uploadStarted[i] + 1;
        }
        setUploadStarted(newUploadStarted);
    };

    const onFileSelected = (isSelected: boolean) => {
        setFileSelected(isSelected);
    };

    return (
        <Box>
            {getFilesIds().map((v, i) => {
                return (
                    <Box key={i}>
                        <FileUploadItem
                            onFileUploaded={onFileUploaded}
                            onFileSelected={onFileSelected}
                            fileType={fileType}
                            btnId={i}
                            startUpload={uploadStarted[i]}
                        />
                    </Box>
                );
            })}
            {multiFile && <WButton
                onClick={addNextFile}
                variant="contained"
                sx={{ mr: '10px' }}
            >+ Dodaj kolejny plik</WButton>}
            <WButton
                disabled={!fileSelected}
                variant="contained"
                onClick={uploadFiles}
            >Prześlij plik</WButton>
        </Box>
    );
};
export default FilesUpload;
