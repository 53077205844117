import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { H3, WLink, WCard } from '../../styles/common';
import http from '../../Api/http';
import { getProvinceById } from '../../utils/helpers';
import { Container, Grid, CardContent, Grow } from '@mui/material';

const CitiesBox = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    http()
      .get('/home/cities')
      .then(({ data }) => {
        setCities(data);
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <Grow in={cities.length > 0}>
        <WCard sx={{ mb: '30px' }}>
          <CardContent>
            <Grid container>
              <Grid item md={12} sx={{ pb: '20px' }}>
                <H3>Polecane usługi dla zwierząt</H3>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {cities.map((city: any, k: number) => {
                return (
                  <LinkWrapper
                    key={k}
                    item
                    md={4}
                    sm={4}
                    xs={12}
                    sx={{
                      padding: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <WLink
                      to={`/weterynarze/${
                        getProvinceById(city.provinceId).slug
                      }/${city.slug}`}
                    >
                      Weterynarz {city.name} ({city.count})
                    </WLink>
                  </LinkWrapper>
                );
              })}
            </Grid>
          </CardContent>
        </WCard>
      </Grow>
    </Container>
  );
};

export default CitiesBox;

// styles

export const LinkWrapper = styled(Grid)({
  padding: 0,
  textAlign: 'left',
});
