import React, { useEffect } from 'react';
import Home from './pages/Home/Home';
import AuthLogin from './pages/AuthLogin/AuthLogin';
import AuthRegister from './pages/AuthRegister/AuthRegister';
import AuthConfirmEmail from './pages/AuthRegister/AuthConfirmEmail';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './store/actions';
import VetsList from './pages/VetsList/VetsList';
import VetDetails from './pages/VetDetails/VetDetails';
import CookiesPolicyPage from './pages/Pages/CookiesPolicyPage';
import TermsConditionsPage from './pages/Pages/TermsConditionsPage';
import CssBaseline from '@mui/material/CssBaseline';
import InfoMessage from './components/InfoMessage/InfoMessage.';
import ScrollToTop from './components/helpers/ScrollToTop';
import ContactPage from './pages/Pages/ContactPage';
import MyPets from './pages/MyPets/MyPets';
import MyVetClinicsList from './pages/MyVetClinics/MyVetClinicsList';
import ProfileSettings from './pages/Profile/ProfileSettings';
import AskVet from './pages/AskVet/AskVet';
import NotFound from './pages/NotFound/NotFound';
import QuestionsToVet from './pages/QuestionsToVet/QuestionsToVet';
import QuestionsToVetDetails from './pages/QuestionsToVet/QuestionsToVetDetails';
import ProfileVerification from './pages/Profile/ProfileVerification';
import MyVetClinicsAdd from './pages/MyVetClinics/MyVetClinicsAdd';
import MyVetClinicsEdit from './pages/MyVetClinics/MyVetClinicsEdit';
import AssignOwner from './pages/AssignOwner/AssignOwner';

// import styled from '@emotion/styled';
// const HomePlaceholder = styled('div')({
//     margin: '100px auto',
//     display: 'block',
//     width: '300px',
//     textAlign: 'center',
//     color: '#30a0b4',
//     fontWeight: 500,
//     fontSize: '20px',
// });

/*  <HomePlaceholder>*/
/*      <img src="/img/img_logo_weteo.png" />*/
/*      <br /><br />*/
/*      Zapraszamy wkrótce...*/
/*  </HomePlaceholder>*/


const App = () => {
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  // const token = localStorage.getItem('wp-token') || '';
  // dispatch(login(token));

  // if ("geolocation" in navigator) {
  //     console.log("Available");
  // } else {
  //     console.log("Not Available");
  // }
  // navigator.geolocation.getCurrentPosition(function(position) {
  //     console.log("Latitude is :", position.coords.latitude);
  //     console.log("Longitude is :", position.coords.longitude);
  // });
  // }, [dispatch]);

  return (
    <AuthProvider>
      <>
        <CssBaseline/>
        <BrowserRouter>
          <ScrollToTop/>
          <Routes>
            <Route path="/">
              <Route index element={<Home/>}/>
              <Route path="logowanie">
                <Route path=":type" element={<AuthLogin/>}/>
                <Route index element={<AuthLogin/>}/>
              </Route>
              <Route path="rejestracja">
                <Route path="potwierdz-email/:userId/:hash" element={<AuthConfirmEmail/>}/>
                <Route path=":type" element={<AuthRegister/>}/>
                <Route index element={<AuthRegister/>}/>
              </Route>
              <Route path="weterynarz/:citySlug/:vetSlug" element={<VetDetails/>}/>
              <Route path="weterynarze">
                <Route path=":provinceSlug/:citySlug" element={<VetsList/>}/>
                <Route index element={<VetsList/>}/>
              </Route>

              {/*<Route path="pytania-do-weterynarza">*/}
              {/*  <Route path=":questionSlug/:type" element={<QuestionsToVetDetails/>}/>*/}
              {/*  <Route path=":questionSlug" element={<QuestionsToVetDetails/>}/>*/}
              {/*  <Route index element={<QuestionsToVet/>}/>*/}
              {/*</Route>*/}

              <Route path="polityka-cookies" element={<CookiesPolicyPage/>}/>
              <Route path="regulamin" element={<TermsConditionsPage/>}/>
              <Route path="kontakt" element={<ContactPage/>}/>

              {/* authenticated routes */}
              <Route path="profil-i-ustawienia">
                <Route index element={<RequireAuth><ProfileSettings/></RequireAuth>}/>
              </Route>
              <Route path="profil-weryfikacja">
                <Route index element={<RequireAuth><ProfileVerification/></RequireAuth>}/>
              </Route>
              <Route path="moje-placowki">
                <Route path="dodaj" element={<RequireAuth><MyVetClinicsAdd/></RequireAuth>}/>
                <Route path="edytuj/:vetSlug" element={<RequireAuth><MyVetClinicsEdit/></RequireAuth>}/>
                <Route index element={<RequireAuth><MyVetClinicsList/></RequireAuth>}/>
              </Route>
              <Route path="jestem-wlascicielem-placowki/:vetSlug">
                <Route index element={<RequireAuth><AssignOwner/></RequireAuth>}/>
              </Route>

              {/*<Route path="moje-zwierzeta">*/}
              {/*    <Route index element={<RequireAuth><MyPets /></RequireAuth>} />*/}
              {/*</Route>*/}
              {/*<Route path="pytania-do-lekarza">*/}
              {/*    <Route index element={<RequireAuth><AskVet /></RequireAuth>} />*/}
              {/*</Route>*/}

              <Route path="404" element={<NotFound/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
        <InfoMessage/>
      </>
    </AuthProvider>
  );
}
export default App;

const AuthProvider = ({children}: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const currentCalled = useSelector((state: any) => state.currentCalled);
  useEffect(() => {
    const token = localStorage.getItem('wp-token') || '';
    dispatch(login(token));
  }, [dispatch]);
  return currentCalled ? children : <></>;
};

const RequireAuth = ({children}: { children: JSX.Element }) => {
  const isAuth = useSelector((state: any) => state.isAuth);
  const location = useLocation();
  if (!isAuth) {
    return <Navigate to="/logowanie" state={{from: location}}/>;
  }
  return children;
};
