export const Colors = {
    black: '#303030',
    lightBlack: '#606060',
    white: '#ffffff',
    darkGrey: '#808080',
    grey: '#cccccc',
    lightGrey: '#dedede',
    lightGrey2: '#f1f1f1',
    vetLightGreen: '#eef9ff',
    vetFormGreen: '#30a0b4',
    vetGreen: '#30b1b4', // main color
    vetDarkGreen: '#1d7b8a',
    vetLightBlue: '#c8e2ff',
    vetBlue: '#1976d2',
    vetDarkBlue: '#065bad',
    fbBlue: '#1b74e4',
    errorRed: '#d32f2f',
    successGreen: '#2e7d32',
};
