import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import http from '../../Api/http';
import { Chip, Pagination } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Tooltip from '@mui/material/Tooltip';
import { H1, H3, H3LinkWithIcon, WButton, WCard, WLink } from '../../styles/common';
import PlaceIcon from '@mui/icons-material/Place';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import marker from '../../assets/marker-icon-2x.png';
import markerA from '../../assets/addr-marker-icon-2x.png';
import styled from '@emotion/styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import type { VetsListParamsType, VetsListParamsKeysType } from '../../types/VetsListParams.types';
import RatingBox from '../../components/RatingBox/RatingBox';
import Loading from '../../components/helpers/Loading';
import config from '../../config/config';
import { getProvinceById } from '../../utils/helpers';

const VetsList = () => {
    const [urlQuery] = useSearchParams();
    const urlParams = useParams();
    const [titleItems, setTitleItems] = useState<string[]>([]);
    const [pageHeader, setPageHeader] = useState<string>('');
    const [vets, setVets] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [city, setCity] = useState<any>({});
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [apiParams, setApiParams] = useState<any>({});
    const [queryParams, setQueryParams] = useState<any>({});
    const navigate = useNavigate();
    const location = useLocation();
    const perPage = 10;

    useEffect(() => {
        const point = urlQuery.get('point');
        const query = urlQuery.get('query') || '';
        const location = urlQuery.get('location') || '';
        const page = parseInt(urlQuery.get('page') || '1', 10);
        const provinceSlug = urlParams.provinceSlug;
        const citySlug = urlParams.citySlug;
        setQueryParams({
            point,
            query,
            location,
        });

        setCity({});
        if (point) {
            setPageHeader(`${urlQuery.get('location') || ''} - najbliższe placówki dla zwierząt`);
            setTitleItems([urlQuery.get('location') || '', 'najbliższe placówki dla zwierząt']);
            const pointSplitted = (point as string).split(',');
            const params = {
                lng: pointSplitted[0] || '',
                lat: pointSplitted[1] || '',
            };
            setApiParams(params);
            getVetsList(page, params);
        } else if (provinceSlug && citySlug) {
            const params = {
                provinceSlug,
                citySlug,
            };
            setApiParams(params);
            getVetsList(page, params);
            getCity(citySlug);
        } else if (query) {
            setPageHeader(`Znalezione placówki dla zwierząt dla zapytania "${query}"`);
            setTitleItems([`Znalezione placówki dla zwierząt dla zapytania "${query}"`]);
            const params = { query };
            setApiParams(params);
            getVetsList(page, params);
        } else {
            setPageHeader('Polecane placówki dla zwierząt w Polsce');
            setTitleItems(['Polecane placówki dla zwierząt w Polsce']);
            const params = { query };
            setApiParams(params);
            getVetsList(page, params);
        }
    }, [urlQuery, urlParams]);

    const nextPage = (page: number, apiParams: any) => {
        let tmpQueryParams = '';
        Object.keys(queryParams).forEach((key) => {
            if (queryParams[key]) {
                tmpQueryParams += `&${key}=${queryParams[key]}`;
            }
        });
        navigate(`${location.pathname}?page=${page}${tmpQueryParams}`);
    };

    const getVetsList = (page: number, params: VetsListParamsType) => {
        setCurrentPage(page);
        setIsLoading(true);
        let apiUrl = `/vets?page=${page-1}&per_page=${perPage}`;
        const paramsKeys: VetsListParamsKeysType[] = Object.keys(params) as VetsListParamsKeysType[];
        for (let i = 0; i < paramsKeys.length; i++) {
            apiUrl = `${apiUrl}&${paramsKeys[i]}=${params[paramsKeys[i]]}`;
        }
        http().get(apiUrl)
            .then(({ data }) => {
                setVets(data.content);
                setTotal(data.total);
            })
            .finally(() => setIsLoading(false));
    }

    const getCity = (citySlug: string) => {
        http().get(`/cities/${citySlug}`)
            .then(({ data }) => {
                setCity(data);
                setPageHeader(`${data.name} - polecane placówki dla zwierząt`);
                setTitleItems([data.name, `woj. ${getProvinceById(data.provinceId).name}`, 'polecane placówki dla zwierząt']);
            });
    };

    const getDistance = (distance: number) => {
        return `${Math.ceil(distance / 1000 * 100) / 100} km`;
    }

    const navigateToVet = (vet: any) => {
        navigate(`/weterynarz/${vet['City.slug']}/${vet.slug}`);
    };

    return (
    <HelmetProvider>
        <Layout>
            <Helmet>
                <title>{`${titleItems.join(' - ')} - weteo.pl`}</title>
                <meta name="description" content={`${titleItems.join('. ')}. Znajdź polecane placówki dla zwierząt w Twojej okolicy.`} />
                <link rel="canonical" href={`${config.baseUrl}${location.pathname}${location.search}`} />
            </Helmet>

            <Grow in={!!pageHeader.length}>
                <Grid container spacing={2} sx={{mt: '10px'}}>
                    <Grid item md={12} sm={12} xs={12}>
                        <H1>{pageHeader}</H1>
                    </Grid>
                </Grid>
            </Grow>

            <Grid container spacing={2} sx={{ mt: '10px' }}>
                <Grid item md={8} sm={12} xs={12}>
                    {isLoading && <Loading />}
                    {!isLoading && vets.length > 0 && vets.map((vet: any, ind: number) => {
                        return (
                            <Grow
                                in={!!(vet && vet.slug)}
                                key={ind}
                            >
                                <WCard sx={{ mb: '20px', display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' } }}>
                                    <CardMedia
                                        onClick={() => navigateToVet(vet)}
                                        component="img"
                                        image={vet['File.path'] ? `${config.staticFilesURL}${vet['File.path']}` : '/img/img_placeholder.png'}
                                        sx={{
                                            width: { md: '200px', sm: '200px', xs: 'auto' },
                                            height: { md: 'auto', sm: 'auto', xs: '200px' },
                                            cursor: 'pointer',
                                        }}
                                    />

                                    <CardContent>
                                        <H3>
                                            {vet.distance && <Chip
                                                sx={{ mr: '5px' }}
                                                size="small"
                                                icon={<PlaceIcon />}
                                                label={`${getDistance(vet.distance)}`} />}
                                            <H3LinkWithIcon to={`/weterynarz/${vet['City.slug']}/${vet.slug}`}>
                                                {vet.name} {vet.verificationDate && <Tooltip title={'Dane placówki zostały potwierdzone przez właściciela oraz serwis weteo.pl'}><VerifiedUserIcon /></Tooltip>}
                                            </H3LinkWithIcon>
                                        </H3>
                                        <RatingBox
                                            reviewsCount={vet.reviewsCount}
                                            reviewsRate={vet.reviewsRate}
                                            size="small"
                                        />
                                        <p>
                                            {vet.street}, {vet.zip} {vet['City.name']}
                                        </p>
                                        {/*({ind + 1 + ((currentPage - 1) * perPage)})*/}
                                        <WButton
                                            size={'small'}
                                            variant={'outlined'}
                                            endIcon={<ArrowRightIcon />}
                                            onClick={() => navigateToVet(vet)}
                                        >
                                            Szczegóły placówki
                                        </WButton>
                                    </CardContent>
                                </WCard>
                            </Grow>
                        );
                    })}

                    {!isLoading && vets.length > 0 && <Box sx={{ mb: '20px' }}>
                        <Pagination
                            count={Math.ceil(total / perPage)}
                            page={currentPage}
                            onChange={(ev, page) => nextPage(page, apiParams)}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Box>}

                    {!isLoading && vets.length === 0 &&
                        <Box>
                            Niestety nie znaleźliśmy żadnych pasujących wyników. Spróbuj poszukać innego słowa.
                            <br />
                            <BackLink to="/"><KeyboardBackspaceIcon /> <span>Wróć na stronę główną</span></BackLink>
                        </Box>
                    }

                    </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    {vets.length > 0 && (city.locationLng || apiParams.lng) && <Grow in={true}>
                        <WCard sx={{mb: 2 }}>
                            <MapCard>
                                <MapContainer
                                    center={apiParams.lat && apiParams.lng ? [parseFloat(apiParams.lat), parseFloat(apiParams.lng)] : [parseFloat(city.locationLat), parseFloat(city.locationLng)]}
                                    zoom={11}
                                    scrollWheelZoom={false}
                                >
                                    {apiParams.lat && apiParams.lng && <Marker
                                        icon={markerIconA}
                                        position={[parseFloat(apiParams.lat), parseFloat(apiParams.lng)]}>
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Popup>
                                            <span>{''}</span>
                                        </Popup>
                                    </Marker>}
                                    {vets.length > 0 && vets.map((vet: any, ind: number) => {
                                        return (
                                            <Marker
                                                key={ind}
                                                icon={markerIcon}
                                                position={[vet.locationLat, vet.locationLng]}>
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Popup>
                                                    <span>
                                                        {vet.name}
                                                        <br />
                                                        {vet.street}, {vet.zip} {vet['City.name']}
                                                    </span>
                                                </Popup>
                                            </Marker>
                                        );
                                    })}
                                </MapContainer>
                            </MapCard>
                        </WCard>
                    </Grow>}
                </Grid>
            </Grid>
        </Layout>
    </HelmetProvider>
    )
};

export default VetsList;


// styles

const markerIconA = new Icon({
    iconUrl: markerA,
    iconSize: [37,61],
});

const markerIcon = new Icon({
    iconUrl: marker,
    iconSize: [37,61],
});

const MapCard = styled('div')({
    height: '500px',
    display: 'block',
    '.leaflet-container': {
        height: '500px',
    },
});

export const BackLink = styled(WLink)({
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    marginBottom: 20,
    svg: {
        marginRight: 5,
    },
});
