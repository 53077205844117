import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Colors } from '../../styles/colors';
import HomeIcon from '@mui/icons-material/Home';
import PlaceIcon from '@mui/icons-material/Place';
import { H2, H4 } from '../../styles/common';
import { Box } from '@mui/material';
import { FontSize } from '../../styles/typography';

export const SearchCard = styled(Card)({
    padding: '20px',
    position: 'relative',
    background: 'none',
    border: 'none',
    boxShadow: 'none',
});

export const BackgroundBox = styled(Box)({
    backgroundColor: Colors.white,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: '0.8',
    zIndex: 1,
});

export const SearchCardBox = styled(Box)({
    zIndex: 1000,
    position: 'relative',
    '.MuiOutlinedInput-root': {
        backgroundColor: Colors.white,
    },
});

export const SearchButton = styled(Button)({
    marginTop: 2,
    boxShadow: 'none',
});

export const H2Styled = styled(H2)({
    color: Colors.vetDarkGreen,
    marginBottom: 10,
    fontSize: 30,
});

export const H4Styled = styled(H4)({
    color: Colors.vetDarkGreen,
    marginBottom: 10,
    fontSize: 24,
});

export const SearchResultsItem = styled('div')({
    padding: '5px 0',
    cursor: 'pointer',
    color: Colors.vetDarkGreen,
    paddingTop: 0,
    fontSize: FontSize.text,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    span: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        //paddingRight: '20px',
        paddingTop: '5px',
    },
});

export const SearchResultsItemHomeIcon = styled(HomeIcon)({
    marginRight: '5px',
    fontSize: '24px',
    color: Colors.vetGreen,
});

export const SearchResultsItemPlaceIcon = styled(PlaceIcon)({
    marginRight: '5px',
    fontSize: '24px',
    color: Colors.grey,
});