import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { FormItemError, H1, H5, InfoSmall, WButton, WCard, WLink, FormGroup, SwitchLabel } from '../../styles/common';
import { CardContent, Grid, Breadcrumbs, Box, TextField, Switch } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../Api/http';
import Loading from '../../components/helpers/Loading';
import { handleErrors } from '../../utils/helpers';
import { setMessage } from '../../store/actions';
import { useNavigate } from 'react-router-dom';

const initialUserData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    pwzNumber: '',
    title: '',
    isPublic: false,
    isVeterinarian: false,
    isClinicOwner: false,
}

const ProfileSettings = () => {
    const [userData, setUserData] = useState<any>(initialUserData);
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const user = useSelector(((state: any) => state.user));

    useEffect(() => {
        loadProfileData();
    }, []);

    const loadProfileData = () => {
        setIsLoading(true);
        http().get('/profile')
            .then(({ data }) => {
                setUserData(data);
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Nie można załadować danych, spróbuj jeszcze raz za chwilę lub zgłoś problem administratorowi.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onFieldChange = (field: string, value: any) => {
        const newUserData: any = { ...userData };
        newUserData[field] = value;
        setUserData(newUserData);
    };

    const onSubmit = (ev: any) => {
        ev.preventDefault();
        setErrors({});

        const dataToSave = { ...userData };
        delete dataToSave.email;
        if (user && user.isVerified) {
            delete dataToSave.pwzNumber;
        }
        if (user && !user.userIsVet) {
            delete dataToSave.pwzNumber;
            delete dataToSave.title;
            delete dataToSave.isPublic;
        }

        setIsSaving(true);
        http().patch('/profile', dataToSave)
            .then(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Profil został zaktualizowany.',
                    type: 'success',
                }));
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/profil-i-ustawienia`}>Profil i ustawienia</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="profile-settings" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>
                            <CardContent>
                                <H1>Profil i ustawienia</H1>

                                {isLoading && <Loading />}

                                {!isLoading && <Box
                                    component="form"
                                    onSubmit={onSubmit}
                                    sx={{
                                        pt: '20px',
                                    }}
                                >

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('email', ev.target.value)}
                                                disabled={true}
                                                required={true}
                                                fullWidth
                                                label="Adres e-mail"
                                                type="email"
                                                size="small"
                                                value={userData.email || ''}
                                                error={errors.email && errors.email.length > 0}
                                            />
                                            {errors.email && <FormItemError>{errors.email}</FormItemError>}
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <InfoSmall>
                                                Adres e-mail to Twój login, dlatego nie można go zmienić. Jeżeli jednak chcesz go zmienić, prosimy o kontakt.
                                            </InfoSmall>
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('firstName', ev.target.value)}
                                                disabled={isSaving}
                                                required={true}
                                                fullWidth
                                                label="Imię"
                                                size="small"
                                                value={userData.firstName || ''}
                                                error={errors.firstName && errors.firstName.length > 0}
                                            />
                                            {errors.firstName && <FormItemError>{errors.firstName}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('lastName', ev.target.value)}
                                                disabled={isSaving}
                                                fullWidth
                                                label="Nazwisko"
                                                size="small"
                                                value={userData.lastName || ''}
                                                error={errors.lastName && errors.lastName.length > 0}
                                            />
                                            {errors.lastName && <FormItemError>{errors.lastName}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    {user && user.userIsVet && <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('title', ev.target.value)}
                                                disabled={isSaving}
                                                fullWidth
                                                label="Tytuł zawodowy / naukowy (np. lek. wet.)"
                                                size="small"
                                                value={userData.title || ''}
                                                error={errors.title && errors.title.length > 0}
                                            />
                                            {errors.title && <FormItemError>{errors.title}</FormItemError>}
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <InfoSmall>
                                                Będzie wyświetlany przed imieniem i nazwiskiem przy dodanych postach i komentarzach w serwisie.
                                            </InfoSmall>
                                        </Grid>
                                    </FormGroup>}

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('phone', ev.target.value)}
                                                disabled={isSaving}
                                                fullWidth
                                                label="Numer telefonu"
                                                size="small"
                                                type="tel"
                                                value={userData.phone || ''}
                                                error={errors.phone && errors.phone.length > 0}
                                            />
                                            {errors.phone && <FormItemError>{errors.phone}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    {/*{user && user.userIsVet && <FormGroup container spacing={2}>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <TextField*/}
                                    {/*            onChange={(ev) => onFieldChange('pwzNumber', ev.target.value)}*/}
                                    {/*            disabled={isSaving || user.isVerified}*/}
                                    {/*            fullWidth*/}
                                    {/*            label="Numer PWZ"*/}
                                    {/*            size="small"*/}
                                    {/*            type="number"*/}
                                    {/*            value={userData.pwzNumber || ''}*/}
                                    {/*            error={errors.pwzNumber && errors.pwzNumber.length > 0}*/}
                                    {/*        />*/}
                                    {/*        {errors.pwzNumber && <FormItemError>{errors.pwzNumber}</FormItemError>}*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        {user && user.isVerified &&<InfoSmall>*/}
                                    {/*            Twoje konto zostało zweryfikowane i nie możesz zmienić numeru PWZ.*/}
                                    {/*        </InfoSmall>}*/}
                                    {/*        {user && !user.isVerified &&<InfoSmall>*/}
                                    {/*            Twoje konto wymaga weryfikacji. Uzupełnij nr PWZ oraz przejdź do zakładki <WLink to="/profil-weryfikacja">Weryfikacja profilu</WLink>, aby dokończyć procedurę.*/}
                                    {/*        </InfoSmall>}*/}
                                    {/*    </Grid>*/}
                                    {/*</FormGroup>}*/}

                                    {/*{user && user.userIsVet && <FormGroup container spacing={2}>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <SwitchLabel>*/}
                                    {/*            <Switch*/}
                                    {/*                disabled={isSaving || !user.isVerified}*/}
                                    {/*                onChange={(ev: any) => onFieldChange('isPublic', ev.target.checked)}*/}
                                    {/*                checked={!!userData.isPublic}*/}
                                    {/*            /> Publiczny profil lekarza weterynarii*/}
                                    {/*        </SwitchLabel>*/}
                                    {/*        {errors.isPublic && <FormItemError>{errors.isPublic}</FormItemError>}*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <InfoSmall>*/}
                                    {/*            Twój profil będzie publicznie dostępny na podstronie z listą lekarzy weterynarii. Możesz zmienić to ustawienie tylko jeżeli Twoje konto jest zweryfikowane.*/}
                                    {/*        </InfoSmall>*/}
                                    {/*    </Grid>*/}
                                    {/*</FormGroup>}*/}

                                    {/*<Box sx={{ mb: '10px' }}><H5>Ustawienia dodatkowe</H5></Box>*/}

                                    {/*<InfoSmall sx={{ mb: '10px' }}>*/}
                                    {/*    <strong>Uwaga! Ze względów bezpieczeństwa, po zmianie i zapisaniu poniższych ustawień, należy się wylogować i zalogować ponownie, aby miały one zastosowanie.</strong>*/}
                                    {/*</InfoSmall>*/}

                                    {/*<FormGroup container spacing={2}>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <SwitchLabel>*/}
                                    {/*            <Switch*/}
                                    {/*                disabled={isSaving}*/}
                                    {/*                onChange={(ev: any) => onFieldChange('isVeterinarian', ev.target.checked)}*/}
                                    {/*                checked={!!userData.isVeterinarian}*/}
                                    {/*            /> Jestem lekarzem weterynarii*/}
                                    {/*        </SwitchLabel>*/}
                                    {/*        {errors.isVeterinarian && <FormItemError>{errors.isVeterinarian}</FormItemError>}*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <InfoSmall>*/}
                                    {/*            Zaznaczając tę opcję, deklarujesz, że jesteś lekarzem weterynarii i poprosimy Cię o zweryfikowanie swojego konta.*/}
                                    {/*        </InfoSmall>*/}
                                    {/*    </Grid>*/}
                                    {/*</FormGroup>*/}

                                    {/*<FormGroup container spacing={2}>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <SwitchLabel>*/}
                                    {/*            <Switch*/}
                                    {/*                disabled={isSaving}*/}
                                    {/*                onChange={(ev: any) => onFieldChange('isClinicOwner', ev.target.checked)}*/}
                                    {/*                checked={!!userData.isClinicOwner}*/}
                                    {/*            /> Jestem właścicielem placówki*/}
                                    {/*        </SwitchLabel>*/}
                                    {/*        {errors.isClinicOwner && <FormItemError>{errors.isClinicOwner}</FormItemError>}*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item md={6} xs={12}>*/}
                                    {/*        <InfoSmall>*/}
                                    {/*            Wybranie tej opcji pozwala zarządzać placówkami dla zwierząt, których jesteś właścicielem w serwisie weteo.pl.*/}
                                    {/*        </InfoSmall>*/}
                                    {/*    </Grid>*/}
                                    {/*</FormGroup>*/}

                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            {isSaving && <Loading />}

                                            {!isSaving && <WButton
                                                disabled={isSaving}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                            >Zapisz zmiany</WButton>}
                                        </Grid>
                                    </Grid>

                                </Box>}

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default ProfileSettings;
