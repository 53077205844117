const commonConfig = {
    version: '0.1.0',
};

type EnvConfig = {
    version?: string;
    name?: string;
    apiUrl: string;
    baseUrl: string;
    staticFilesURL: string;
};

const config: EnvConfig = {
    ...commonConfig,
    apiUrl: process.env.REACT_APP_API_URL || '',
    staticFilesURL: process.env.REACT_APP_STATIC || '',
    baseUrl: 'https://weteo.pl',
};

export default config;
