import * as actionTypes from './actions';
import type TUser from '../types/User.types';
import { IMessage } from './actions';

type InitialState = {
    user?: TUser | null;
    token?: string | null;
    isAuth: boolean;
    message?: IMessage;
    currentCalled?: boolean;
};

const initialState: InitialState = {
    user: null,
    token: null,
    isAuth: false,
    currentCalled: false,
    message: {
        isOpen: false,
        content: '',
        type: null,
    },
};

const rootReducer = (state: InitialState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                user: action.user,
                token: action.token,
                isAuth: action.isAuth,
                currentCalled: action.currentCalled,
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                user: null,
                token: null,
                isAuth: false,
                currentCalled: true,
            };
        case actionTypes.SET_MESSAGE:
            return {
                ...state,
                message: action.message || initialState.message,
            };
        default:
            return state;
    }
};

export default rootReducer;