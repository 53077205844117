import React from 'react';
import styled from '@emotion/styled';
import { Rating } from '@mui/material';
import Box from '@mui/material/Box';
import { WLinkExt } from '../../styles/common';

const RatingBox = (
    { reviewsCount, reviewsRate, onLinkClick, size = 'medium' }:
        { reviewsCount: number, reviewsRate: number, onLinkClick?: () => void, size?: 'small' | 'medium' }
) => {
    const getReviewsLabel = (count: number) => {
        if (count === 0 || count >= 5) {
            return 'opinii';
        } else if (count === 1) {
            return 'opinia';
        } else if (count >= 2 && count <= 4) {
            return 'opinie';
        }
    };

    return (
        <RatingBoxContainer>
            <RatingBoxRating
                value={reviewsRate}
                max={6}
                size={size}
                readOnly
                precision={0.1}
            />
            {reviewsRate > 0 && <RatingBoxRate size={size}>{reviewsRate}</RatingBoxRate>}
            {onLinkClick && <RatingBoxLink size={size} onClick={onLinkClick}>({reviewsCount} {getReviewsLabel(reviewsCount)})</RatingBoxLink>}
            {!onLinkClick && <RatingBoxInfo size={size}>({reviewsCount} {getReviewsLabel(reviewsCount)})</RatingBoxInfo>}
        </RatingBoxContainer>
    );
};
export default RatingBox;

const RatingBoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px !important',
});

const RatingBoxRating = styled(Rating)({
    marginRight: 10,
});

const RatingBoxRate = styled(Box)(({ size }: { size: any }) => ({
    fontSize: size === 'small' ? 14 : 15,
    marginRight: 10,
    fontWeight: 'bold',
}));

const RatingBoxLink = styled(WLinkExt)(({ size }: { size: any }) => ({
    fontSize: size === 'small' ? 14 : 15,
    cursor: 'pointer',
}));

const RatingBoxInfo = styled(Box)(({ size }: { size: any }) => ({
    fontSize: size === 'small' ? 14 : 15,
}));