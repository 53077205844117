import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { H1, WCard, WLink } from '../../styles/common';
import {
    CardContent,
    Grid,
    Breadcrumbs,
    Box, Tab, Tabs,
} from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import http from '../../Api/http';
import { setMessage } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EditVetClinicForm from './components/EditVetClinicForm';
import Loading from '../../components/helpers/Loading';
import EditProfilePhoto from './components/EditProfilePhoto';
import EditOpeningHours from './components/EditOpeningHours';

const MyVetClinicsEdit = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [vetClinicData, setVetClinicData] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(((state: any) => state.user));
    const params = useParams();

    useEffect(() => {
        if (user && !user.userIsOwner) {
            // TODO return <Navigate to="" />;
        } else if (params && params.vetSlug) {
            loadVetClinicData(params.vetSlug);
        }
    }, []);

    const loadVetClinicData = (vetSlug: string) => {
        setIsLoading(true);
        http().get(`/user-vets/${vetSlug}`)
            .then(({ data }) => {
                setVetClinicData(data);
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Nie można załadować danych. Odśwież stronę i spróbuj ponownie. Jeżeli błąd będzie się powtarzał - prosimy o kontakt.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onProfilePhotoChanged = (fileId: any) => {
        const newVetData = { ...vetClinicData };
        newVetData.profileFileId = fileId;
        setVetClinicData(newVetData);
    };

    // const onTabChange = (selected: number) => {
    //
    // };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-placowki`}>Moje placówki</WLink>
                            {params && params.vetSlug && <WLink variant="small" to={`/moje-placowki/edytuj/${params.vetSlug}`}>Edycja placówki</WLink>}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="my-vet-clinics" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>

                            <Tabs
                                value={selectedTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Dane placówki" onClick={() => setSelectedTab(0)} />
                                <Tab label="Zdjęcie główne" onClick={() => setSelectedTab(1)} />
                                <Tab label="Godziny otwarcia" onClick={() => setSelectedTab(2)} />
                                {/*<Tab label="Usługi i specjalizacje" onClick={() => setSelectedTab(3)} />*/}
                            </Tabs>

                            {selectedTab === 0 && <CardContent>
                                <Box>
                                    {isLoading && <Loading />}
                                    {!isLoading && vetClinicData && <EditVetClinicForm
                                        vetData={vetClinicData}
                                    />}
                                </Box>
                            </CardContent>}

                            {selectedTab === 1 && <CardContent>
                                <Box>
                                    {isLoading && <Loading />}
                                    {!isLoading && vetClinicData && <Box>
                                        <EditProfilePhoto
                                            onProfilePhotoChanged={onProfilePhotoChanged}
                                            vetSlug={vetClinicData.slug}
                                            profileFileId={vetClinicData.profileFileId}
                                        />
                                    </Box>}
                                </Box>
                            </CardContent>}

                            {selectedTab === 2 && <CardContent>
                                <Box>
                                    {isLoading && <Loading />}
                                    {!isLoading && vetClinicData && <Box>
                                        <EditOpeningHours
                                            vetSlug={vetClinicData.slug}
                                        />
                                    </Box>}
                                </Box>
                            </CardContent>}

                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default MyVetClinicsEdit;
