import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Grid, InputLabel, Link as MLink } from '@mui/material';
import { Colors } from './colors';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export const H1 = styled('h1')({
    fontWeight: 500,
    fontSize: 22,
    margin: 0,
    color: Colors.black,
});

export const H2 = styled('h2')({
    fontWeight: 500,
    fontSize: 20,
    margin: 0,
    color: Colors.black,
});

export const H3 = styled('h3')({
    fontWeight: 500,
    fontSize: 18,
    margin: 0,
    color: Colors.black,
    // display: 'flex',
    // alignItems: 'center',
});

export const H4 = styled('h4')({
    fontWeight: 500,
    fontSize: 17,
    margin: 0,
    color: Colors.black,
});

export const H5 = styled('h5')({
    fontWeight: 500,
    fontSize: 16,
    margin: 0,
    color: Colors.black,
});

export const WButton = styled(Button)(({ variant }) => ({
    textTransform: 'none',
    backgroundColor: variant === 'contained' ? Colors.vetGreen : Colors.white,
    borderColor: variant === 'contained' ? 'none' : Colors.vetDarkGreen,
    color: variant === 'contained' ? Colors.white : Colors.vetDarkGreen,
    boxShadow: 'none',
    ':hover' : {
        backgroundColor: variant === 'contained' ? Colors.vetDarkGreen : Colors.vetLightGreen,
        borderColor: variant === 'contained' ? 'none' : Colors.vetDarkGreen,
        boxShadow: 'none',
    },
}));

export const WBlueButton = styled(Button)(({ variant }) => ({
    textTransform: 'none',
    backgroundColor: variant === 'contained' ? Colors.vetBlue : Colors.white,
    borderColor: variant === 'contained' ? 'none' : Colors.vetDarkBlue,
    color: variant === 'contained' ? Colors.white : Colors.vetDarkBlue,
    boxShadow: 'none',
    ':hover' : {
        backgroundColor: variant === 'contained' ? Colors.vetDarkBlue : Colors.vetLightBlue,
        borderColor: variant === 'contained' ? 'none' : Colors.vetDarkBlue,
        boxShadow: 'none',
    },
}));

export const H3Link = styled(Link)({
    color: Colors.black,
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'underline',
    },
});

export const H3LinkWithIcon = styled(H3Link)({
    // display: 'flex',
    // alignItems: 'center',
    svg: {
        marginLeft: 5,
        color: Colors.vetBlue,
    },
});

export const WLink = styled(Link)((props: any) => ({
    color: props.color === 'white' ? Colors.white : Colors.vetDarkGreen,
    textDecoration: 'none',
    fontSize: props.variant === 'small' ? 14 : 15,
    ':hover': {
        color: props.color === 'white' ? Colors.white : Colors.vetGreen,
        textDecoration: 'underline',
    }
}));

export const WLinkExt = styled(MLink)({
    color: Colors.vetDarkGreen,
    textDecoration: 'none',
    ':hover': {
        color: Colors.vetGreen,
        textDecoration: 'underline',
    }
});

export const WCard = styled(Card)({
    boxShadow: 'none',
    border: `solid 1px ${Colors.lightGrey}`,
});

export const FormItemError = styled(Box)({
    color: Colors.errorRed,
    marginBottom: '5px',
    fontSize: 15,
});

export const FormGroup = styled(Grid)({
    marginBottom: 20,
});

export const InfoSmall = styled(Box)({
    fontSize: 13,
    color: Colors.lightBlack,
    a: {
        fontSize: 13,
    },
});

export const SwitchLabel = styled(InputLabel)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

export const UploadButton = styled(InputLabel)({
    fontSize: 15,
    width: 200,
    textAlign: 'center',
    backgroundColor: Colors.vetGreen,
    color: Colors.white,
    padding: '6px 16px',
    cursor: 'pointer',
    borderRadius: 4,
    ':hover': {
        backgroundColor: Colors.vetDarkGreen,
    }
});