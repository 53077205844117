import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { WLink, H5, WLinkExt } from '../../styles/common';
import { Colors } from '../../styles/colors';
import FacebookIcon from '@mui/icons-material/Facebook';
import styled from '@emotion/styled';

const FooterWrapper = styled(Box)({
    h5: {
        marginBottom: 15,
        color: Colors.white,
    },
    p: {
        margin: '0 0 10px 0',
    },
    paddingBottom: 40,
});

const Footer = () => {
    return (
        <FooterWrapper
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: Colors.vetGreen,
            }}
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item md={3} sm={6} xs={12}>
                        <H5>&copy; weteo.pl 2022</H5>
                        <p>
                            <FbWLinkExt
                                href="https://www.facebook.com/WeteoPL"
                                target="_blank"
                            >
                                <FacebookIcon sx={{ color: Colors.white }} /> WeteoPL
                            </FbWLinkExt>
                        </p>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <H5>Dla właścicieli zwierząt</H5>
                        {/*<p><WLink color="white" to={'/zapytaj-weterynarza'}>Zapytaj weterynarza</WLink></p>*/}
                        {/*<p><WLink color="white" to={'/pytania-i-odpowiedzi'}>Pytania i odpowiedzi</WLink></p>*/}
                        {/*<p><WLink color="white" to={'/uslugi'}>Usługi</WLink></p>*/}
                        {/*<p><WLink color="white" to={'/spcjalizacje'}>Specjalizacje</WLink></p>*/}
                        <p><WLink color="white" to={'/rejestracja'}>Rejestracja</WLink></p>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <H5>Dla biznesu</H5>
                        <p><WLink color="white" to={'/logowanie'}>Logowanie</WLink></p>
                        <p><WLink color="white" to={'/rejestracja/weterynarz'}>Rejestracja</WLink></p>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <H5>O serwisie</H5>
                        <p><WLink color="white" to={'/kontakt'}>Kontakt</WLink></p>
                        {/*<p><WLink color="white" to={'/mapa-strony'}>Mapa strony</WLink></p>*/}
                        <p><WLink color="white" to={'/regulamin'}>Regulamin</WLink></p>
                        <p><WLink color="white" to={'/polityka-cookies'}>Polityka Cookies</WLink></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <CookiesInfo>
                            Serwis internetowy weteo.pl do poprawnego działania wykorzystuje pliki cookies.
                            <br />
                            Korzystając z serwisu weteo.pl akceptujesz postanowienia <WLink color="white" to={'/polityka-cookies'}>Polityki Cookies</WLink>.
                        </CookiesInfo>
                    </Grid>
                </Grid>
            </Container>
        </FooterWrapper>
    );
};

export default Footer;

// styles
const FbWLinkExt = styled(WLinkExt)({
    color: Colors.white,
    display: 'flex',
    alignItems: 'center',
    ':hover': {
        color: Colors.lightGrey,
        textDecoration: 'none',
        svg: {
            color: Colors.lightGrey,
        },
    },
    svg: {
        marginRight: 3,
    },
});

const CookiesInfo = styled(Box)({
    color: Colors.white,
    fontSize: 14,
    a: {
        fontSize: 14,
        textDecoration: 'underline',
    },
});
