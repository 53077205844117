import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch,
    TextField,
} from '@mui/material';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import { handleErrors } from '../../../utils/helpers';
import Loading from '../../../components/helpers/Loading';
import { FormGroup, FormItemError, WButton, H5, InfoSmall, SwitchLabel } from '../../../styles/common';
import { useDispatch } from 'react-redux';
import { provinces, vetTypes } from '../../../config/constants';

const initialVetClinicData: any = {
    name: '',
    type: 10,
    street: '',
    zip: '',
    city: '',
    province: 1,
    phone: '',
    phone2: '',
    email: '',
    www: '',
};

const EditVetClinicForm = ({ vetData }: { vetData: any }) => {
    const [vetClinicData, setVetClinitData] = useState<any>(initialVetClinicData);
    const [errors, setErrors] = useState<any>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const vet: any = {
            ...initialVetClinicData,
            ...vetData,
        };
        vet.city = vetData['City.name'];
        vet.province = vetData['City.provinceId'];
        setVetClinitData(vet);
    }, []);

    // const user = useSelector(((state: any) => state.user));
    // TODO: check user type to redirect if now owner

    const onFieldChange = (field: string, value: any) => {
        const newVetData: any = { ...vetClinicData };
        newVetData[field] = value;
        setVetClinitData(newVetData);
    };

    const onSubmit = (ev: any) => {
        ev.preventDefault();
        setErrors({});
        const dataToSave = {...vetClinicData};
        setIsSaving(true);
        http().patch(`/user-vets/${vetClinicData.slug}`, dataToSave)
            .then(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Placówka została zaktualizowana.',
                    type: 'success',
                }));
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <Box
            component="form"
            onSubmit={onSubmit}
        >

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <SwitchLabel>
                        <Switch
                            disabled={isSaving}
                            onChange={(ev: any) => onFieldChange('isActive', ev.target.checked)}
                            checked={!!vetClinicData.isActive}
                        /> Placówka aktywna
                    </SwitchLabel>
                    {errors.isActive && <FormItemError>{errors.isActive}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <InfoSmall>
                        Włączenie tej opcji sprawia, że Twoja placówka będzie dostępna do przeglądania przez użytkowników serwisu.
                    </InfoSmall>
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('name', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Nazwa placówki"
                        size="small"
                        value={vetClinicData.name || ''}
                        error={errors.name && errors.name.length > 0}
                    />
                    {errors.name && <FormItemError>{errors.name}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Typ placówki</InputLabel>
                        <Select
                            onChange={(ev) => onFieldChange('type', ev.target.value)}
                            disabled={isSaving}
                            required={true}
                            fullWidth
                            label="Typ placówki"
                            size="small"
                            value={vetClinicData.type || ''}
                            error={errors.type && errors.type.length > 0}
                        >
                            {vetTypes.map((vetType, key: number) => {
                                return (
                                    <MenuItem value={vetType.id} key={key}>
                                        {vetType.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {errors.type && <FormItemError>{errors.type}</FormItemError>}
                </Grid>
            </FormGroup>


            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('firstName', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Imię właściciela"
                        size="small"
                        value={vetClinicData.firstName || ''}
                        error={errors.firstName && errors.firstName.length > 0}
                    />
                    {errors.firstName && <FormItemError>{errors.firstName}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('lastName', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Nazwisko właściciela"
                        size="small"
                        value={vetClinicData.lastName || ''}
                        error={errors.lastName && errors.lastName.length > 0}
                    />
                    {errors.lastName && <FormItemError>{errors.lastName}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        disabled={true}
                        fullWidth
                        label="Nazwa w adresie URL"
                        size="small"
                        value={vetClinicData.slug || ''}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <InfoSmall>
                        Automatycznie wygenerowana nazwa placówki w adresie strony internetowej serwisu weteo.pl jest unikalnym identyfikatorem Twojej placówki. Nie zalecamy jej częstej zmiany ponieważ ma to wpływ na wyniki w wyszukiwarce Google. Jeżeli chcesz ją jednak zmienić skontaktuj się z nami.
                    </InfoSmall>
                </Grid>
            </FormGroup>



            <Box sx={{ mb: '10px' }}>
                <H5>Adres placówki</H5>
            </Box>
            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('street', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Ulica i numer"
                        size="small"
                        value={vetClinicData.street || ''}
                        error={errors.street && errors.street.length > 0}
                    />
                    {errors.street && <FormItemError>{errors.street}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('zip', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Kod pocztowy"
                        size="small"
                        value={vetClinicData.zip || ''}
                        error={errors.zip && errors.zip.length > 0}
                    />
                    {errors.zip && <FormItemError>{errors.zip}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('city', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Miejscowość"
                        size="small"
                        value={vetClinicData.city || ''}
                        error={errors.city && errors.city.length > 0}
                    />
                    {errors.city && <FormItemError>{errors.city}</FormItemError>}
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Województwo</InputLabel>
                        <Select
                            onChange={(ev) => onFieldChange('province', ev.target.value)}
                            disabled={isSaving}
                            required={true}
                            fullWidth
                            label="Województwo"
                            size="small"
                            value={vetClinicData.province || ''}
                            error={errors.province && errors.province.length > 0}
                        >
                            {provinces.map((province, key: number) => {
                                return (
                                    <MenuItem value={province.id} key={key}>
                                        {province.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {errors.province && <FormItemError>{errors.province}</FormItemError>}
                </Grid>
            </FormGroup>


            <Box sx={{ mb: '10px' }}>
                <H5>Dane kontaktowe placówki</H5>
            </Box>
            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('phone', ev.target.value)}
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Numer telefonu główny"
                        size="small"
                        type="tel"
                        value={vetClinicData.phone || ''}
                        error={errors.phone && errors.phone.length > 0}
                    />
                    {errors.phone && <FormItemError>{errors.phone}</FormItemError>}
                </Grid>

                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('phone2', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Numer telefonu dodatkowy"
                        size="small"
                        type="tel"
                        value={vetClinicData.phone2 || ''}
                        error={errors.phone2 && errors.phone2.length > 0}
                    />
                    {errors.phone2 && <FormItemError>{errors.phone2}</FormItemError>}
                </Grid>
            </FormGroup>

            <FormGroup container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('email', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Adres e-mail"
                        size="small"
                        type="email"
                        value={vetClinicData.email || ''}
                        error={errors.email && errors.email.length > 0}
                    />
                    {errors.email && <FormItemError>{errors.email}</FormItemError>}
                </Grid>

                <Grid item md={6} xs={12}>
                    <TextField
                        onChange={(ev) => onFieldChange('www', ev.target.value)}
                        disabled={isSaving}
                        fullWidth
                        label="Adres strony internetowej"
                        size="small"
                        type="text"
                        value={vetClinicData.www || ''}
                        error={errors.www && errors.www.length > 0}
                    />
                    {errors.www && <FormItemError>{errors.www}</FormItemError>}
                </Grid>
            </FormGroup>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    {isSaving && <Loading />}

                    {!isSaving && <WButton
                        disabled={isSaving}
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                    >Zapisz zmiany</WButton>}
                </Grid>
            </Grid>
        </Box>
    );
};
export default EditVetClinicForm;
