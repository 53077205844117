import React, { useCallback, useRef, useState, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, CardMedia, Input, LinearProgress } from '@mui/material';
import ReactCrop from 'react-image-crop';
import { UploadButton, WButton, H5, InfoSmall } from '../../../styles/common';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import config from '../../../config/config';
import Loading from '../../../components/helpers/Loading';
import DeleteIcon from '@mui/icons-material/Delete';

const EditProfilePhoto = (
    { vetSlug, profileFileId, onProfilePhotoChanged }:
        { vetSlug: number, profileFileId: number, onProfilePhotoChanged?: (fileId: any) => void }
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [loadedFile, setLoadedFile] = useState<any>(null);
    const [crop, setCrop] = useState<any>({
        unit: '%',
        width: 100,
        aspect: 2,
    });
    const [uploadedImage, setUploadedImage] = useState<any>(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState<any>(null);
    const dispatch = useDispatch();

    const width = 600;
    const height = 300;

    useEffect(() => {
        if (profileFileId) {
            loadProfilePhoto(profileFileId);
        }
    }, [profileFileId]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: any = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = width;
        canvas.height = height;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            width,
            height
        );
    }, [completedCrop]);

    const loadProfilePhoto = (fileId: number) => {
        setIsLoading(true);
        http().get(`/files/${fileId}`)
            .then(({ data }) => {
                setLoadedFile(data);
            })
            .catch(() => {
                // consider it as there is no photo loaded
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setUploadedImage(null);
            const file = e.target.files[0];
            const allowedTypes = ['image/png', 'image/jpeg'];
            const allowedSize = 5242880;

            if (file.size > allowedSize) {
                return dispatch(setMessage({
                    isOpen: true,
                    content: 'Błąd! Plik graficzny nie może być większy nić 5MB.',
                    type: 'error',
                }));
            } else if (!allowedTypes.includes(file.type)) {
                return dispatch(setMessage({
                    isOpen: true,
                    content: 'Błąd! Można wybrać tylko plik graficzny JPG lub PNG.',
                    type: 'error',
                }));
            }

            const reader: any = new FileReader();
            reader.addEventListener('load', () => setUploadedImage(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const generateDownload = (canvas: any, crop: any) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            (blob: any) => {
                upload(new File(
                    [blob],
                    'vetClinicProfilePhoto.jpg',
                    {
                        type: 'image/jpeg',
                        lastModified: new Date().getTime(),
                    }
                ));
            },
            'image/jpeg',
            1
        );
    }

    const upload = (file: any) => {
        setProgress(0);
        setIsUploading(true);
        setTimeout(() => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', '6');
            http().post(`/files/upload/${vetSlug}`, formData, {
                onUploadProgress: (ProgressEvent) => {
                    const fileProgress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                    setProgress(fileProgress);
                },
            })
                .then(({ data }) => {
                    if (onProfilePhotoChanged) {
                        onProfilePhotoChanged(data.id);
                    }
                    setUploadedImage(null);
                    dispatch(setMessage({
                        isOpen: true,
                        content: 'Zdjęcie zostało przesłane.',
                        type: 'success',
                    }));
                })
                .catch((error) => {
                    setProgress(0);
                    const status = error.response.status;
                    let content = 'Błąd! Nie można przesłać pliku, spróbuj ponownie za chwilę.';
                    if (status === 413) {
                        content = 'Rozmiar pliku przekracza 5MB. Załaduj mniejszy plik.';
                    }
                    dispatch(setMessage({
                        isOpen: true,
                        content,
                        type: 'error',
                    }));
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }, 500);
    };

    const deleteProfilePhoto = (fileId: number) => {
        setIsLoading(true);
        http().delete(`/files/${fileId}`)
            .then(() => {
                setLoadedFile(null);
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Zdjęcie zostało usunięte.',
                    type: 'success',
                }));
                if (onProfilePhotoChanged) {
                    onProfilePhotoChanged(null);
                }
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Błąd! Nie można usunąć zdjęcia, spróbuj ponownie za chwilę.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Box>
            {isLoading && <Loading />}

            {!isLoading && !isUploading && loadedFile && <Box>
                <Box sx={{
                    mb: '10px',
                }}>
                    <H5>Aktualne zdjęcie główne placówki:</H5>
                </Box>
                <CardMedia
                    component="img"
                    image={`${config.staticFilesURL}${loadedFile.path}`}
                    sx={{
                        width: {
                            md: '600px',
                            sm: '500px',
                            xs: '100%',
                        },
                        height: {
                            md: '300px',
                            sm: '250px',
                        },
                    }}
                />
                <Box sx={{
                    pt: '10px',
                }}>
                    <WButton
                        variant="outlined"
                        onClick={() => deleteProfilePhoto(profileFileId)}
                        startIcon={<DeleteIcon />}
                    >Usuń zdjęcie</WButton>
                    <InfoSmall>Po usunięciu aktualnego zdjęcia możesz dodać nowe.</InfoSmall>
                </Box>
            </Box>}

            {!isLoading && isUploading && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress value={progress} variant="determinate"/>
                </Box>
                <Box sx={{ minWidth: '50px', pl: '10px' }}>
                    {progress}%
                </Box>
            </Box>}

            {!isLoading && !isUploading && !loadedFile && <Box>

                <Box sx={{ mb: '20px' }}>
                    <UploadButton
                        htmlFor="uploadProfileImage"
                    ><Input
                        id="uploadProfileImage"
                        type="file"
                        onChange={onSelectFile}
                        sx={{ display: 'none' }}
                    />Wybierz plik</UploadButton>
                    <InfoSmall>Dozwolony typ pliku to JPG lub PNG, rozmiar nie powinien przekraczać 5MB. </InfoSmall>
                </Box>

                {uploadedImage && <Box sx={{ mb: '20px' }}>
                    <Box sx={{ mb: '10px' }}>
                        <H5>Na zdjęciu poniżej wykadruj obszar, który będzie zapisany jako zdjęcie główne Twojej placówki:</H5>
                    </Box>
                    <ReactCrop
                        src={uploadedImage}
                        onImageLoaded={onLoad}
                        crop={crop}
                        keepSelection={true}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                </Box>}

                {uploadedImage && <Box sx={{ mb: '10px' }}>
                    <H5>Podgląd zdjęcia głównego placówki:</H5>
                    <canvas
                        ref={previewCanvasRef}
                        style={{ width: `${width}px`, height: `${height}px` }}
                    />
                </Box>}

                {uploadedImage && <WButton
                    variant="contained"
                    disabled={!completedCrop?.width || !completedCrop?.height}
                    onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
                >Zapisz wykadrowane zdjęcie</WButton>}

            </Box>}
        </Box>
    );
};
export default EditProfilePhoto;