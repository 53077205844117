import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../styles/colors';
import { Collapse, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '../Avatar/Avatar';

const Header = () => {
    const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const isAuth = useSelector((state: any) => state.isAuth);
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const menuOpen = Boolean(anchorEl);

    const onLogout = () => {
        dispatch(logout());
        handleMenuClose();
    };

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (to?: string) => {
        setAnchorEl(null);
        if (to) {
            navigate(to);
        }
    };

    return (
        <AppBarStyled position="static">
            <Container maxWidth="lg">
                <ToolbarStyled>
                    <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', 'a': { lineHeight: 0.7  }}}>
                        <LogoLink to="/">
                            <img
                                src="/img/img_logo.svg"
                                alt="weteo.pl"
                                width="177"
                                height="40"
                            />
                        </LogoLink>
                    </Box>

                    <MenuBox
                        sx={{
                            display: {
                                sm: 'none',
                                xs: 'none',
                                md: 'flex',
                            },
                        }}
                    >
                        {/*<MenuLink*/}
                        {/*    title={'Zapytaj weterynarza'}*/}
                        {/*    to={'/pytania-do-weterynarza'}*/}
                        {/*>*/}
                        {/*    Zapytaj weterynarza*/}
                        {/*</MenuLink>*/}
                        <MenuLink
                            title={'Polecane usługi'}
                            to={'/weterynarze'}
                        >
                            Usługi dla zwierząt
                        </MenuLink>
                        {isAuth &&
                        <LoggedUser
                            onClick={handleMenuOpen}
                        >
                            <Avatar user={user} sx={{ width: '24px', height: '24px', fontSize: '12px', mr: '5px' }} /> {user.firstName} {user.lastName || ''} <KeyboardArrowDownIcon />
                        </LoggedUser>}
                        {isAuth && <Menu
                            open={menuOpen}
                            anchorEl={anchorEl}
                            onClose={() => handleMenuClose()}
                        >
                            {/*{user && user.userIsVet && <MenuItem onClick={() => handleMenuClose('/pytania-do-lekarza')}>Moje odpowiedzi</MenuItem>}*/}
                            {/*<MenuItem onClick={() => handleMenuClose('/moje-zwierzeta')}>Moje zwierzęta</MenuItem>*/}
                            <MenuItem onClick={() => handleMenuClose('/profil-i-ustawienia')}>Profil i ustawienia</MenuItem>
                            {user && user.userIsOwner && <MenuItem onClick={() => handleMenuClose('/moje-placowki')}>Moje placówki</MenuItem>}
                            <MenuItem onClick={onLogout}>Wyloguj się</MenuItem>
                        </Menu>}
                        {!isAuth && <MenuLink to={'/logowanie'}>Zaloguj się</MenuLink>}
                        {/*{!isAuth && <MenuLink to={'/rejestracja'}>Załóż konto</MenuLink>}*/}
                    </MenuBox>
                    <MenuBoxMobile sx={{
                        display: {
                            xs: 'flex',
                            sm: 'flex',
                            md: 'none',
                        },
                    }}>
                        {!mobileMenuOpened && <MenuIcon onClick={() => setMobileMenuOpened(true)} />}
                        {mobileMenuOpened && <CloseIcon onClick={() => setMobileMenuOpened(false)} />}
                    </MenuBoxMobile>
                </ToolbarStyled>
                <Collapse in={mobileMenuOpened}>
                    <MobileMenuLinks sx={{
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'none',
                        },
                    }}>
                        {isAuth && <LoggedUserMobile>
                            <PersonOutlineIcon/>
                            {user.firstName} {user.lastName || ''}
                            <HButton onClick={() => onLogout()} variant="outlined" size="small" color="inherit">Wyloguj się</HButton>
                        </LoggedUserMobile>}

                        <MobileMenuLink
                            title={'Lista placówek dla zwierząt'}
                            to={'/weterynarze'}
                        >Placówki dla zwierząt</MobileMenuLink>

                        {/*<MobileMenuLink*/}
                        {/*    title={'Zapytaj weterynarza'}*/}
                        {/*    to={'/pytania-do-weterynarza'}*/}
                        {/*>Zapytaj weterynarza</MobileMenuLink>*/}

                        {/*{isAuth && user && user.userIsVet && <MobileMenuLink to={'/pytania-do-lekarza'}>Moje odpowiedzi</MobileMenuLink>}*/}
                        {/*{isAuth && <MobileMenuLink to={'/moje-zwierzeta'}>Moje zwierzęta</MobileMenuLink>}*/}
                        {isAuth && <MobileMenuLink to={'/profil-i-ustawienia'}>Profil i ustawienia</MobileMenuLink>}
                        {isAuth && user && user.userIsOwner && <MobileMenuLink to={'/moje-placowki'}>Moje placówki</MobileMenuLink>}

                        {!isAuth && <MobileMenuLink to={'/logowanie'}>Zaloguj się</MobileMenuLink>}
                        {/*{!isAuth && <MobileMenuLink to={'/rejestracja'}>Załóż konto</MobileMenuLink>}*/}
                    </MobileMenuLinks>
                </Collapse>
            </Container>
        </AppBarStyled>
    );
};

export default Header;

// styles
const AppBarStyled = styled(AppBar)({
    backgroundColor: Colors.vetGreen,
});

const ToolbarStyled = styled(Toolbar)({
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
});

const LogoLink = styled(Link)({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none',
    },
});

const HButton = styled(Button)({
    textTransform: 'none',
});

const LoggedUser = styled('div')({
    alignItems: 'center',
    display: 'flex',
    marginLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    position: 'relative',
    fontSize: 15,
});

const LoggedUserMobile = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'right',
    button: {
        marginLeft: 20,
    },
});

const MenuBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    a: {
        marginLeft: 20,
    },
});

const MenuBoxMobile = styled(Box)({
    cursor: 'pointer',
});

const MobileMenuLinks = styled(Box)({
    paddingBottom: 10,
});

const MenuLink = styled(Link)({
    color: Colors.white,
    textDecoration: 'none',
    fontSize: 15,
    ':hover': {
        textDecoration: 'underline',
    }
});

const MobileMenuLink = styled(Link)({
    color: Colors.white,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: 15,
    display: 'block',
    textAlign: 'right',
    padding: 10,
    ':hover': {
        textDecoration: 'underline',
    }
});
