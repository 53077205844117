import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import { H1, WLinkExt } from '../../styles/common';
import config from '../../config/config';

const ContactPage = () => {
    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{'Kontakt - weteo.pl'}</title>
                    <meta name="description" content="Kontakt serwisu weteo.pl" />
                    <link rel="canonical" href={`${config.baseUrl}/kontakt`} />
                </Helmet>
                <Grid container spacing={2} sx={{ mt: '10px' }}>
                    <Grid item md={12} sm={12} xs={12}>
                        <H1>Kontakt</H1>
                    </Grid>
                    <Grid item md={12}>
                        <p>
                            Jeżeli masz pytania dotyczące serwisu weteo.pl, zapraszamy do kontaktu poprzez poniższy adres e-mail:
                        </p>
                        <WLinkExt href="mailto:weteo.pl@gmail.com">weteo.pl@gmail.com</WLinkExt>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};

export default ContactPage;
