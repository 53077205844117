import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import http from '../../Api/http';
import { useDebounce } from '../../utils/hooks';
import { useNavigate } from 'react-router-dom';
import {
    SearchCard,
    BackgroundBox,
    SearchCardBox,
    SearchResultsItem,
    SearchResultsItemHomeIcon,
    SearchResultsItemPlaceIcon,
    SearchButton,
    H2Styled,
    H4Styled,
} from './SearchBox.styles';

const SearchBox = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);
    const navigate = useNavigate();

    useEffect(() => {
        if (debouncedSearchQuery) {
            onSearch(debouncedSearchQuery);
        }
    }, [debouncedSearchQuery]);

    const onSearch = (query: string = '') => {
        if (query.length >= 3) {
            http().get('/search/vets?query=' + query).then(({data}) => {
                setSearchResults(data);
            });
        }
    };

    const onSearchSelect = (value: any) => {
        if (typeof value === 'string') {
            navigate(`/weterynarze/?query=${value}`);
        } else {
            if (value.type === 'vet') {
                navigate(`/weterynarz/${value.citySlug}/${value.slug}`);
            } else if (value.type === 'point') {
                navigate(`/weterynarze/?point=${value.position.lon},${value.position.lat}&location=${value.name}`);
            }
        }
    };

    return (
        <SearchCard
            sx={{
                marginTop: {
                    sm: '50px',
                    md: 0,
                },
                borderRadius: {
                    xs: 0,
                },
                border: {
                    xs: 0,
                },
            }}
        >
            <BackgroundBox />
            <SearchCardBox
                component="form"
                onSubmit={() => onSearchSelect(searchQuery)}
                sx={{ padding: { md: '50px 30px 20px' } }}
            >
                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mb: '15px',
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                                md: 'left',
                            },
                        }}
                    >
                        <H2Styled>
                            Wyszukaj usługi dla zwierząt
                            <br />
                            w Twojej okolicy
                        </H2Styled>
                        Znajdź placówkę po nazwie lub wpisz adres, a my wyświetlimy listę tych, które są najbliżej Ciebie
                    </Grid>
                    <Grid item md={9} sm={9} xs={12}>
                        <Autocomplete
                            freeSolo
                            onInputChange={(event, newValue) => {
                                setSearchQuery(newValue);
                            }}
                            onChange={(ev, values) => onSearchSelect(values)}
                            options={searchResults}
                            filterOptions={(options) => options}
                            getOptionLabel={(option: any) => option && option.name ? option.name : option}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size={'small'}
                                    variant="outlined"
                                    label="Wyszukaj placówkę lub adres"
                                />
                            }
                            renderOption={(props: any, option) => {
                                return (
                                    <li {...props} key={option.recId}>
                                        <SearchResultsItem>
                                            {option.type === 'vet' && <SearchResultsItemHomeIcon />}
                                            {option.type === 'point' && <SearchResultsItemPlaceIcon />}
                                            <span>{option.name}</span>
                                        </SearchResultsItem>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item md={3} sm={3} xs={12}>
                        <SearchButton
                            type="submit"
                            fullWidth={true}
                            variant="contained"
                            startIcon={<SearchIcon />}>Szukaj</SearchButton>
                    </Grid>
                </Grid>
            </SearchCardBox>
        </SearchCard>
    );
};
export default SearchBox;


// <Grid item md={12} sm={12} xs={12}>
//     <Grid container spacing={1}>
//         <Grid item md={9} sm={9} xs={12}>
//             <Autocomplete
//                 clearOnEscape={false}
//                 clearOnBlur={false}
//                 onInputChange={(event, newValue) => {
//                     console.log('spec: ', newValue);
//                 }}
//                 options={specializations.map((item: { name: string }) => item.name)}
//                 renderInput={(params) =>
//                     <TextField {...params}
//                                variant="standard"
//                                label="Wybierz specjalizację"
//                     />
//                 }
//             />
//         </Grid>
//     </Grid>
// </Grid>
