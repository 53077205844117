import React, { useEffect, useRef, useState } from 'react';
import { FormItemError, UploadButton } from '../../styles/common';
import { Box, Input, InputLabel, LinearProgress } from '@mui/material';
import http from '../../Api/http';
import styled from '@emotion/styled';
import { Colors } from '../../styles/colors';
import DeleteIcon from '@mui/icons-material/Delete';

const FileUploadItem = (
    { btnId = 0, startUpload = 0, fileType = 1, onFileSelected, onFileUploaded }:
        { btnId?: number, startUpload: number, fileType?: number, onFileSelected?: (isSelected: boolean) => void, onFileUploaded?: () => void }
) => {
    const [file, setFile] = useState<any>({});
    const [progress, setProgress] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const fileRef = useRef<any>();

    useEffect(() => {
        if (startUpload > 0 && file && file.size) {
            upload();
        }
    }, [startUpload]);

    const onFileChange = (e: any) => {
        setProgress(0);
        setError('');
        const file = e.target.files[0];
        setFile(file);
        if (onFileSelected) {
            onFileSelected(true);
        }
    }

    const onFileDelete = () => {
        setFile({});
        if (onFileSelected) {
            onFileSelected(false);
        }
    }

    const upload = () => {
        setError('');
        setProgress(0);
        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', file); // appending file
        formData.append('type', fileType.toString()); // appending file
        http().post('/files/upload', formData, {
                onUploadProgress: (ProgressEvent) => {
                    const fileProgress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                    setProgress(fileProgress);
                },
            })
            .then(() => {
                setIsUploading(false);
                if (onFileUploaded) {
                    onFileUploaded();
                }
            })
            .catch((error) => {
                setProgress(0);
                setIsUploading(false);
                const status = error.response.status;
                if (status === 413) {
                    setError('Rozmiar pliku przekracza 5MB. Załaduj mniejszy plik.');
                } else if (status === 409) {
                    setError('Plik został już przesłany wcześniej.');
                }
            })
            .finally(() => {

            });
    };

    return (
        <Box
            sx={{
                // border: 'solid 1px red',
                minHeight: '70px',
                mb: '10px',
            }}
        >
            {file && file.name && <Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
                <Box sx={{ minWidth: '30px', pr: '10px' }}>
                    {!isUploading && <DeleteIconStyled onClick={onFileDelete} />}
                </Box>
                <Box sx={{ width: '100%' }}>
                    <FileName>{file.name}</FileName>
                </Box>
            </Box>}

            {!isUploading && !(file && file.name) && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UploadButton
                    htmlFor={`uploadFileInput_${btnId}`}
                >
                    <Input
                        id={`uploadFileInput_${btnId}`}
                        type="file"
                        sx={{ display: 'none' }}
                        inputRef={fileRef}
                        onChange={onFileChange}
                    />
                    Wybierz plik (max 5MB)
                </UploadButton>
            </Box>}

            {isUploading && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress value={progress} variant="determinate"/>
                </Box>
                <Box sx={{ minWidth: '50px', pl: '10px' }}>
                    {progress}%
                </Box>
            </Box>}
            {error && <FormItemError>{error}</FormItemError>}
        </Box>
    );
};
export default FileUploadItem;

// styles
const FileName = styled(Box)({
    fontSize: 14,
    maxWidth: '90%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
});

const DeleteIconStyled = styled(DeleteIcon)({
    cursor: 'pointer',
    color: Colors.lightBlack,
});
