import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import http from '../../Api/http';
import Layout from '../../components/Layout/Layout';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styled from '@emotion/styled';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import marker from '../../assets/marker-icon-2x.png';
import { Icon } from 'leaflet';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { InfoSmall, WButton, WLink } from '../../styles/common';
import { WLinkExt } from '../../styles/common';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Dialog, DialogContent, DialogTitle, DialogActions, Breadcrumbs, Grow } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { H1, H3, WCard } from '../../styles/common';
import { getProvinceById } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Reviews from '../../components/Comments/Reviews';
import RatingBox from '../../components/RatingBox/RatingBox';
import Loading from '../../components/helpers/Loading';
import config from '../../config/config';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import OpeningHours from './components/OpeningHours';

const markerIcon = new Icon({
    iconUrl: marker,
    iconSize: [37,61],
});

const VetDetails = () => {
    const params = useParams();
    const reviewsRef = useRef(null);
    const [vetData, setVetData] = useState<any>(null); // TODO add type VET
    const [isPhoneNumberModalVisible, setIsPhoneNumberModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        http().get(`/vets/${params.vetSlug}`)
            .then(({ data }) => {
                setVetData(data);
            })
            .catch(() => {
                navigate('/404');
            })
            .finally(() => setIsLoading(false));
    }, [params]);

    const showPhoneNumber = () => {
        setIsPhoneNumberModalVisible(true);
        http().patch(`/vets/${params.vetSlug}/phone_views`);
    };

    const getPhoneNumber = (phone: string = '') => {
        return `${phone.slice(0, phone.length/2 + 1)} ...`;
    }

    const handleCloseModal = () => {
        setIsPhoneNumberModalVisible(false);
    };

    const scrollToReviews = () => {
        if (reviewsRef && reviewsRef.current) {
            (reviewsRef.current as any).scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const goToAssignOwnerPage = (slug: string) => {
        navigate(`/jestem-wlascicielem-placowki/${slug}`);
    };

    const navigateToAddress = () => {
        window.open(
            `https://www.google.com/maps/dir/?api=1&origin=%20&destination=${vetData.street},%20${vetData.zip}%20${vetData['City.name']}&travelmode=driving`
        );
    };

    return (
        <HelmetProvider>
            <Layout>

                {vetData &&
                <Helmet>
                    <title>{`${vetData.name} - ${vetData['City.name']} - woj. ${getProvinceById(vetData['City.provinceId']).name} - weteo.pl`}</title>
                    <meta name="description" content={`${vetData.name}, ${vetData['City.name']}, woj. ${getProvinceById(vetData['City.provinceId']).name}. Znajdź polecane placówki dla zwierząt w Twojej okolicy.`} />
                    <link rel="canonical" href={`${config.baseUrl}${location.pathname}${location.search}`} />
                </Helmet>}

                {isLoading && <Loading sx={{ pt: '50px' }} />}

                {!isLoading && vetData &&
                    <Grow in={!!(vetData && vetData.slug)}>
                        <Grid container spacing={2} sx={{mt: '10px'}}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                                    <WLink variant="small" to={`/`}>weteo.pl</WLink>
                                    <WLink variant="small"
                                           to={`/weterynarze/${getProvinceById(vetData['City.provinceId']).slug}/${vetData['City.slug']}`}>
                                        {vetData['City.name']} - polecane placówki dla zwierząt
                                    </WLink>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <H1>{vetData.name}</H1>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} sx={{ paddingTop: '5px !important' }}>
                                <RatingBox
                                    reviewsCount={vetData.reviewsCount}
                                    reviewsRate={vetData.reviewsRate}
                                    onLinkClick={scrollToReviews}
                                />
                            </Grid>
                        </Grid>
                    </Grow>
                }
                <Grid container spacing={2} sx={{ mt: '10px' }}>
                    <Grid item md={8} sm={12} xs={12}>
                        {!isLoading && vetData &&
                        <Grow in={!!(vetData && vetData.slug)}>
                            <WCard
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column',
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={vetData['File.path'] ? `${config.staticFilesURL}${vetData['File.path']}` : '/img/img_placeholder.png'}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '35%',
                                            xs: '100%',
                                        },
                                    }}
                                />
                                <CardContent>
                                    <H3 style={{marginBottom: '15px'}}>Dane placówki</H3>
                                    <Box sx={{display: 'flex', mb: 2, alignItems: 'center'}}>
                                        <PlaceIcon sx={{mr: 1}}/> {vetData.street}, {vetData.zip} {vetData['City.name']}
                                    </Box>
                                    {vetData.phone && <Box sx={{display: 'flex', mb: 2, alignItems: 'center'}}>
                                        <LocalPhoneIcon sx={{mr: 1}}/>
                                        <span>{getPhoneNumber(vetData.phone)}</span>
                                        <WButton
                                            onClick={() => showPhoneNumber()}
                                            sx={{ml: 2}}
                                            size="small"
                                            variant="outlined">Pokaż numer telefonu</WButton>
                                    </Box>}
                                    {vetData.www && <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                                        <LanguageIcon sx={{mr: 1}}/>
                                        <WLinkExt href={`${vetData.www}?utm_source=weteo.pl`}
                                                  target="_blank">{vetData.www}</WLinkExt>
                                    </Box>}
                                    <Box>
                                        <WButton
                                            onClick={() => {
                                                navigateToAddress()
                                            }}
                                            variant="contained"
                                            startIcon={<NavigationIcon/>}
                                        >Wyznacz trasę</WButton>
                                    </Box>
                                    {/*<Box>*/}
                                    {/*    <WButton variant={'contained'} startIcon={<MailOutlineIcon/>}>Wyślij*/}
                                    {/*        wiadomość</WButton>*/}
                                    {/*</Box>*/}
                                </CardContent>


                            </WCard>
                        </Grow>
                        }

                        {!isLoading && vetData && vetData.slug && <div ref={reviewsRef}><Reviews vetSlug={vetData.slug} /></div>}

                    </Grid>

                    <Grid item md={4} sm={12} xs={12}>

                        {vetData && !vetData.ownerAssigned && <Box sx={{ mb: '10px' }}>
                            <WButton
                                onClick={() => goToAssignOwnerPage(vetData.slug)}
                                fullWidth={true}
                                variant="contained"
                                startIcon={<VerifiedUserIcon/>}
                            >Jestem właścicielem tej placówki</WButton>
                        </Box>}

                        {!isLoading && vetData && Array.isArray(vetData.openingHours) && vetData.openingHours.length > 0 &&
                            <OpeningHours items={vetData.openingHours} />
                        }

                        {!isLoading && vetData && vetData.locationLat && vetData.locationLng &&
                        <Grow in={!!(vetData && vetData.slug)}>
                            <WCard sx={{ mb: 2 }}>
                                {isMobile && vetData.locationLat && vetData.locationLng && <Box sx={{p: 2}}>
                                    <WButton
                                        onClick={() => {
                                            navigateToAddress()
                                        }}
                                        fullWidth={true}
                                        variant="contained"
                                        startIcon={<NavigationIcon/>}
                                    >Wyznacz trasę</WButton>
                                </Box>}

                                {vetData && vetData.locationLat && vetData.locationLng && <MapCard>
                                    <MapContainer
                                        center={[vetData.locationLat, vetData.locationLng]}
                                        zoom={16}
                                        scrollWheelZoom={false}
                                    >
                                        <Marker icon={markerIcon} position={[vetData.locationLat, vetData.locationLng]}>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Popup>
                                                <span>{vetData.name}<br/>{vetData.fullAddress}</span>
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                </MapCard>}

                                {vetData && vetData.locationLat && vetData.locationLng && <Box sx={{p: 2}}>
                                    <WButton
                                        onClick={() => {
                                            navigateToAddress()
                                        }}
                                        fullWidth={true}
                                        variant="contained"
                                        startIcon={<NavigationIcon/>}
                                    >Wyznacz trasę</WButton>
                                </Box>}

                            </WCard>
                        </Grow>
                        }
                    </Grid>
                </Grid>


                <Dialog
                    open={isPhoneNumberModalVisible}
                    onClose={handleCloseModal}
                    PaperProps={{
                        sx: {
                            position: 'fixed',
                            top: '100px',
                        },
                    }}
                >
                    <DialogTitle>Numer telefonu placówki</DialogTitle>
                    <DialogContent>
                        {vetData && vetData.phone &&
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <PhoneWrapper>
                                {isMobile && <WLinkExt href={`tel:${vetData.phone}`}><LocalPhoneIcon /> {vetData.phone}</WLinkExt>}
                                {!isMobile && <><LocalPhoneIcon /> {vetData.phone}</>}
                            </PhoneWrapper>
                            {vetData.phone2 && <>
                            <PhoneWrapper>
                                {isMobile && <WLinkExt href={`tel:${vetData.phone2}`}><LocalPhoneIcon /> {vetData.phone2}</WLinkExt>}
                                {!isMobile && <><LocalPhoneIcon /> {vetData.phone2}</>}
                            </PhoneWrapper></>}
                        </Typography>}

                        <InfoSmall sx={{ pt: '20px' }}>
                            Podczas rozmowy wspomnij, że kontakt
                            <br />
                            do placówki uzyskano w serwisie
                            <br />
                            weteo.pl
                        </InfoSmall>
                    </DialogContent>
                    <DialogActions>
                        <WButton onClick={handleCloseModal}>Zamknij</WButton>
                    </DialogActions>
                </Dialog>

            </Layout>
        </HelmetProvider>
    )
};
export default VetDetails;

// styles
const MapCard = styled('div')({
    height: '500px',
    display: 'block',
    '.leaflet-container': {
        height: '500px',
    },
});

const PhoneWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    svg: {
        marginRight: 10,
    },
    a: {
        display: 'flex',
        alignItems: 'center',
        svg: {
            marginRight: 10,
        },
    }
});
