import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { H1, H5, WCard, WLink, WLinkExt } from '../../styles/common';
import { CardContent, Grid, Breadcrumbs, Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadIcon from '@mui/icons-material/Download';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import FilesUpload from '../../components/FilesUpload/FilesUpload';
import { useSelector } from 'react-redux';
import http from '../../Api/http';
import styled from '@emotion/styled';
import { Colors } from '../../styles/colors';
import config from '../../config/config';
import Loading from '../../components/helpers/Loading';

const ProfileVerification = () => {
    const [userFiles, setUserFiles] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const user = useSelector(((state: any) => state.user));

    useEffect(() => {
        if (user && !user.isVerified) {
            reloadFiles();
        }
        // TODO: redirect if user not vet
    }, []);

    const reloadFiles = () => {
        setIsLoading(true);
        http().get('/files/type/10')
            .then(({ data }) => {
                setUserFiles(data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const onFileUploaded = () => {
        reloadFiles();
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/profil-weryfikacja`}>Weryfikacja profilu</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="profile-verification" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>
                            <CardContent>
                                <H1>Weryfikacja profilu</H1>

                                {user && user.userIsVet && <Box>
                                    {user.isVerified && <Box>
                                        <p>
                                            Twoje konto zostało <strong>zweryfikowane!</strong>
                                        </p>
                                    </Box>}

                                    {!user.isVerified && <Box>
                                        <p>
                                            Typ Twojego konta to <strong>lekarz weterynarii</strong>.
                                        </p>
                                        <p>
                                            Aby zapewnić bezpieczeństwo Tobie oraz innym użytkownikom serwisu weteo.pl
                                            prosimy o przesłanie pliku z zaświadczeniem o nadanym numerze PWZ.
                                        </p>
                                        <p>
                                            Po pozytywnej weryfikacji plik zostanie usunięty, a do Twojego konta zostanie przypisany tylko wskazany numer PWZ.
                                        </p>

                                        {isLoading && <Loading />}

                                        {!isLoading && userFiles.length === 0 && <FilesUpload
                                            onFileUploaded={onFileUploaded}
                                            fileType={10}
                                        />}

                                        {!isLoading && userFiles.length > 0 && <FileAlreadyUploaded>
                                            Plik został przesłany, prosimy oczekiwać na weryfikację.
                                            <br />
                                            {userFiles.map((f: any, k: number) => {
                                                return (
                                                    <Box key={k} sx={{ display: 'flex', pt: '10px' }}>
                                                        Nazwa przesłanego pliku:&nbsp;<strong>{f.name}</strong>
                                                        <WLinkExt
                                                            sx={{ ml: '10px' }}
                                                            href={`${config.staticFilesURL}${f.path}`}
                                                            target="_blank"
                                                        >
                                                            <DownloadIcon />
                                                        </WLinkExt>
                                                    </Box>
                                                );
                                            })}
                                        </FileAlreadyUploaded>}
                                    </Box>}

                                    <Box sx={{ pt: '20px' }}>
                                        <H5>Korzyści zweryfikowanego konta:</H5>
                                        <ul>
                                            <li>
                                                Możliwość opublikowania swojego profilu lekarza na podstronie "Lekarze weterynarii".
                                            </li>
                                            <li>
                                                Możliwość udzielenia odpowiedzi na pytania zadane przez użytkowników.
                                            </li>
                                            <li>
                                                Podgląd e-książeczki zdrowia zwierząt użytkowników wraz z możliwością dodania wpisu.
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>}

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default ProfileVerification;

// styles
const FileAlreadyUploaded = styled(Box)({
    borderRadius: 4,
    border: `solid 1px ${Colors.lightGrey}`,
    padding: 20,
    fontSize: 15,
});
