import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CardContent, Grid } from '@mui/material';
import { H1, WCard } from '../../styles/common';
import config from '../../config/config';

const CookiesPolicyPage = () => {
    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{'Polityka Cookies - weteo.pl'}</title>
                    <meta name="description" content="Treść polityki cookies serwisu weteo.pl" />
                    <link rel="canonical" href={`${config.baseUrl}/polityka-cookies`} />
                </Helmet>
                <WCard sx={{ mt: '20px', mb: '20px' }}>
                    <CardContent>
                        <Grid container>
                            <Grid item md={12}>
                                <H1>Polityka Cookies</H1>
                                <p>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.</p>

                                <p>Pliki cookies (tzw. "ciasteczka") stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>

                                <p>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu: firma ELITEIT z siedzibą w Poznania (Polska).</p>

                                <p><strong>Pliki cookies wykorzystywane są w celu:</strong></p>

                                <ul>
                                    <li>dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</li>
                                    <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
                                    <li>utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła.</li>
                                </ul>

                                <p>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: "sesyjne" (session cookies) oraz "stałe" (persistent cookies). Cookies "sesyjne" są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). "Stałe" pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p>

                                <p><strong>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</strong></p>

                                <ul>
                                    <li>"niezbędne" pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;</li>
                                    <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;</li>
                                    <li>"wydajnościowe" pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;</li>
                                    <li>"funkcjonalne" pliki cookies, umożliwiające "zapamiętanie" wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.</li>
                                </ul>

                                <p>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. <strong>Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. </strong>Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</p>

                                <p><strong>Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</strong></p>

                                <p>Więcej informacji na temat plików cookies dostępnych jest w sekcji "Pomoc" w menu przeglądarki internetowej.</p>
                            </Grid>
                        </Grid>
                    </CardContent>
                </WCard>
            </Layout>
        </HelmetProvider>
    );
};

export default CookiesPolicyPage;
